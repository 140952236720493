import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import {Link } from '@mui/material'; 
import FactorsUpdateTable from './FactorsUpdateTable'
import useSortConfig from '../../../sort_config.js';

const FactorsSearchTable = ({
    showTable,
    inputTableData,
    onRowClick
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { sortConfig, SortButton } = useSortConfig();


    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            //console.log(inputTableData)
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (!showTable) {
        return null;
    }

    const handleDownload = () => {

        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'Factorssearch_table.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleLinkClick = (rowData) => (event) => {  
        console.log("rowdata rendering is",rowData);    
        event.preventDefault();     
        onRowClick(rowData); // Call the function passed via props with the row data    
        // ...other props you might need  
 
    };  
    function capitalizeFirstLetter(string) {  
        if (string) {  
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();  
        }  
        return '';  
    }

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });


    return (
        <Paper sx={{ paddingLeft: '0.2rem', boxShadow: 'none', width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleDownload}
                    id='download-button'
                >
                    Download
                </Button>
            </div>
            <TableContainer component={Paper} style={{ width: '100%' , maxHeight: 480 }}>
                <Table aria-label="simple table" stickyHeader style={{ width: '100%' }}>
                    <TableHead style={{ backgroundColor: '#b0c0da' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Data Segment</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>
                                <SortButton label="Factor Name" sortKey="FACTOR_NAME" start='ascending' />
                            </TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Begin Payment Process Period</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>End Payment Process Period</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Factor</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Active</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Last Cap Process Period</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Create Date</TableCell>
                            <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', }}>Update Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow key={row.DATA_SEGMENT}>
                                        <TableCell style={{ textAlign: 'left' }}>{row.DATA_SEGMENT}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>  
                                            <Link href="#" onClick={handleLinkClick(row)} style={{ cursor: 'pointer', textDecoration: 'none' }}>  
                                                {row.FACTOR_NAME}  
                                            </Link>  
                                        </TableCell> 
                                        <TableCell style={{ textAlign: 'left' }}>{row.BEGIN}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.END}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{!isNaN(parseFloat(row.FACTOR)) ? parseFloat(row.FACTOR).toFixed(3) : row.FACTOR}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{capitalizeFirstLetter(row.ACTIVE)}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.LAST_CAP}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.CREATE}</TableCell>
                                        <TableCell style={{ textAlign: 'left' }}>{row.UPDATE}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                    },
                }}
            />

        </Paper>
    );
};

export default FactorsSearchTable;
