import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { CodesSearch } from './CodesSearch'; 
import {CodesAdd} from './CodesAdd';
import { CodesUpdate } from './CodesUpdate'; 
import {CodesUpload} from './CodesUpload';
import { HelpButtons } from '../../../components/helpPrintButtons.js';



const sections = [
    "Search",
    "Add",
    "Update",
    "Upload",
];

export const AdminCodes = () => {
    const [value, setValue] = useState(0);
    const [disabledTabs, setDisabledTabs] = useState([
        false,
        false,
        false,
        false,
      
    ]);
    // set tab selection value (0, 1, 2, 3, 4)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
 
    // only adjust component if value changes
    const MakeTabComponent = () => {
        const [currentTab, setCurrentTab] = useState(null);
        useEffect(() => {
            setCurrentTab(makeTabComponent(value));
        }, [value]);
        return currentTab;
    };
    // actual components to show depending on tab value
    const makeTabComponent = (value) => {
        if (value === 0) {
            return <CodesSearch/>;
        }
        if (value === 1) {
            return <CodesAdd/>;
        }
        if (value === 2) {
            return <CodesUpdate/>;
        }
           if (value === 3) {
            return <CodesUpload/>;
        }
      
    };
    return (
        <div>
            <div style={{ position: 'absolute', top: '0', right: '20px', zIndex: 1}}>
                <HelpButtons index={0}/>
            </div>
            <div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: "#002153" }, pt: 1.5 }}
                >
                    {sections.map((section, index) => (
                        <Tab
                            label={section}
                            disabled={disabledTabs[index]}
                        />
                    ))}
                </Tabs>
                <MakeTabComponent />
            </div>
        </div>
    );
};

