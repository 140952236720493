import axios from "axios";
import { getToken } from "../../TokenAPI.js";

const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/search`;
const MEMBER_ROLES_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/update`;
const GET_THRESHOLDS_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/get_thresholds`;
const UPDATE_THRESHOLD_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/update_threshold`;

const bearerToken = await getToken();
const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }

export const memberRolesAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_URL,
  headers: headers,
});
export var memberRolesJsonSchema = {
  role: [],
};
export const memberRolesUpdateAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_UPDATE_URL,
  headers: headers,
});

export const getThresholdsApi = async () => {
	const bearerToken = await getToken();
	return axios.get(
		GET_THRESHOLDS_URL,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`	
			}
		});
}

export const updateThresholdApi = async (requestBody) => {
	const bearerToken = await getToken();
	return axios.post(
		UPDATE_THRESHOLD_URL,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`	
			}
		});
}
