import { useEffect, useState } from "react";
import { Alert, Box, Button, MenuItem, Select, Typography, CircularProgress } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from "@mui/icons-material/Cancel";
import Papa from 'papaparse';
import { OverridesUploadApi } from './ReportingOverridesUploadApi';
import { getDataSegmentIntFromString, getDataSegments } from "../../../Home/dataSegmentApi";
import { testPDSeg } from '../../../testPopupDataSegment';
import { useSelector } from "react-redux";


export const ReportingOverridesUpload = ({ switchTab }) => {

    const [uiMessages, setUiMessages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [results, setResults] = useState([]);


    const userId = localStorage.getItem("sub");
    const [dataSegment, setDataSegment] = useState("");
    const [dataSegments, setDataSegments] = useState([]);
    const email = useSelector((store) => store.user.userInfo.email);

    useEffect(() => {
        setDataSegments(getDataSegments());
    }, []);

    const handleUpload = () => {
        const file = document.getElementById('fileInput').files[0];
        if (!dataSegment) {
            setUiMessages([['error', 'Please select a data segment']]);
            return;
        }
        if (!file) {
            setUiMessages([['error', 'Please select a file to upload']]);
            return;
        }

        setUploading(true);

        // Parse CSV file, pass the data into the API, send csv with errors to the end user
        Papa.parse(file, {
            header: true,
            delimiter: ",",
            skipEmptyLines: true,
            quoteChar: '"',
            escapeChar: '\\',
            complete: async function (results) {
                console.log("Parsed data: ", results.data);

                const temp = await handleUploadApiCall(results.data);
                
                // download immediately (doesn't work)
                // const csvBlob = generateCsvBlob(temp);
                // downloadCsv(csvBlob);

                setCompleted(true);
                setUploading(false);
            },
            error: function (error) {
                console.log("Error parsing CSV file: ", error);
                setUiMessages([['error', 'Error parsing CSV file']]);
                setUploading(false);
            }
        })
    }

    const generateCsvBlob = (rowsWithErrors) => {
        const csv = Papa.unparse(rowsWithErrors);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        return blob;
    }

    const downloadCsv = (csvBlob) => {
        const url = window.URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'error_rows.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleUploadApiCall = async (csvData) => {
        var requestBody = [];
        for (let i = 0; i < csvData.length; i++) {
            const rowData = csvData[i];
            // Trim the white space for the PROV_ARNG_CD
            rowData.PROV_ARNG_CD = rowData.PROV_ARNG_CD.trim();
            // Additional mappings
            rowData.DATA_SEG_ID_UPLOAD = `${getDataSegmentIntFromString(dataSegment)}`;
            rowData.UPDT_USER_ID = `${userId}`;
            rowData.EMAIL_ID = `${email}`;
            // Map name change for doc_id
            rowData.DOCUMENT_ID = rowData.DOC_ID;
            delete rowData.DOC_ID;
            requestBody.push(rowData);
        }
        console.log(requestBody);

        var response = await OverridesUploadApi(requestBody);
        var result = response.data;

        setResults([result[result.length - 1][1]['Success'], result[result.length - 1][0]['Error']]);

        return result;
    }

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    return (
        <>
            {completed ?
                <Box sx={{ color: 'green' }}>
                    <Typography variant='h6'>
                        ReportingOverrides upload completed. See summary below. Detailed report is sent to {email}.
                    </Typography>
                    <Typography variant='h6'>Success: {results[0]}</Typography>
                    <Typography variant='h6'>Failed: {results[1]}</Typography>
                </Box>
                :
                <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: 'flex', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }} >
                            <Typography variant='h6' sx={{ color: 'red' }}>*</Typography>
                            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>Select a Data Segment</Typography>
                        </Box>
                        <Select
                            size='small'
                            sx={{ minWidth: 130 }}
                            value={dataSegment}
                            onChange={(event) => setDataSegment(event.target.value)}
                        >
                            {dataSegments.map((row) => (
                                <MenuItem key={row.NAME} value={row.NAME}>
                                    {row.NAME}
                                </MenuItem>
                            ))}
                        </Select>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                alt="data-seg-pop-up-icon"
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box><Typography variant='body2'>Please select a CSV (Comma Separated Values) file</Typography></Box>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ alignContent: 'center', width: 200 }}>
                            <Typography variant='h6' sx={{ display: 'flex' }}><p style={{ color: 'red' }}>*</p>Select File to Upload</Typography>
                        </Box>
                        <input id='fileInput' type="file" accept=".csv" />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant='contained'
                            startIcon={<CloudUploadIcon />}
                            sx={{ mr: 1 }}
                            onClick={handleUpload}
                            disabled={uploading}
                        >
                            Upload
                            {uploading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            startIcon={<CancelIcon />}
                            onClick={() => switchTab(0)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box >
            }
            <Box sx={{ width: 500, mt: 2 }}>
                {uiMessages.map((message, index) => {
                    if (message[0] === 'error') {
                        return (
                            <Alert
                                severity='error'
                                size='small'
                                key={'alert_' + index}
                                sx={{ mb: 0.5, opacity: '.9', '&:hover': { backgroundColor: '#ffb6a7', cursor: 'pointer' } }}
                                onClick={() => setUiMessages(uiMessages.filter((_, i) => i !== index))}
                            >
                                {message[1]}
                            </Alert>)
                    } else if (message[0] === 'warning') {
                        return (
                            <Alert
                                severity='warning'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    } else if (message[0] === 'success') {
                        return (
                            <Alert
                                severity='success'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    }
                })}
            </Box>
        </>
    )

    // return (
    //     <Box>
    //         <Grid container spacing={.5} mt={1.5} >
    //             <Grid sm={4} md={4} pl={2}>
    //                 <Box sx={{ display: 'flex' }}>
    //                     <Box sx={{ alignContent: 'center', width: 150 }}>
    //                         <InputLabel
    //                             sx={{
    //                                 display: "flex",
    //                                 justifyContent: "left",
    //                                 fontWeight: 'bold',
    //                                 fontSize: '0.875rem',
    //                                 color: 'black',
    //                                 flex: 1,
    //                                 whiteSpace: 'nowrap'
    //                             }}
    //                         >
    //                             Data Segment<span style={{ color: 'red' }}>*</span>
    //                         </InputLabel>
    //                     </Box>
    //                     <Select
    //                         labelId="data-seg-select"
    //                         id="data-seg-select"
    //                         value={dataSegment}
    //                         onChange={dataSegmentChange}
    //                         sx={{ width: 140, height: 40 }}
    //                     >
    //                         {dataSegments.map((dataSegment) => (
    //                             <MenuItem key={dataSegment} value={dataSegment}>{dataSegment}</MenuItem>

    //                         ))}
    //                     </Select><div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
    //                         <img
    //                             alt="data-seg-pop-up-icon"
    //                             style={{ height: '80%', width: '80%' }}
    //                             src="Binoculars-icon.png"
    //                             title="Filtering Data segment"
    //                             onClick={() => openPopupDataSegment()}
    //                         ></img>
    //                     </div>
    //                 </Box>
    //                 <Box sx={{ mt: 1 }}>
    //                     <InputLabel
    //                         sx={{
    //                             display: "flex",
    //                             justifyContent: "left",
    //                             fontWeight: 'bold',
    //                             fontSize: '0.875rem',
    //                             color: 'black',
    //                             flex: 1,
    //                             whiteSpace: 'nowrap'
    //                         }}
    //                     >
    //                         Select File to Upload<span style={{ color: 'red' }}>*</span>
    //                     </InputLabel>
    //                     <Box><Typography variant='body2'>Please select a CSV (Comma Separated Values) file</Typography></Box>
    //                     <Box>
    //                         <input id='fileInput' type="file" accept=".csv" />
    //                     </Box>
    //                     <Box sx={{ display: 'flex', mt: 1 }}>
    //                         <Button
    //                             variant='contained'
    //                             startIcon={<CloudUploadIcon />}
    //                             sx={{ mr: 1 }}
    //                             onClick={handleUpload}
    //                             disabled={loading}
    //                         >
    //                             Upload
    //                         </Button>
    //                         <Button variant="contained" color='error' startIcon={<CancelIcon />} onClick={() => switchTab(0)}>Cancel</Button>
    //                     </Box>
    //                 </Box>
    //             </Grid>
    //             <Grid sm={4} md={4}></Grid>
    //             <Grid sm={4} md={4} container direction="column" justifyContent="flex-top" alignItems="flex-end">
    //                 <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
    //                     <CardMedia sx={{ padding: 2, backgroundColor: '#F5F5F5' }}>
    //                         <Box display="flex" justifyContent="space-between" alignItems="center">
    //                             <Avatar sx={{ bgcolor: green[500], width: 56, height: 56 }} >
    //                                 <AssignmentIcon style={{ fontSize: 40 }} />
    //                             </Avatar>
    //                             <Box flexGrow={1} mx={2}>
    //                                 {loading && <LinearProgress color="secondary" />}
    //                             </Box>
    //                             <Avatar sx={{ bgcolor: pink[500], width: 56, height: 56 }} >
    //                                 <StorageIcon style={{ fontSize: 40 }} />
    //                             </Avatar>
    //                         </Box>
    //                     </CardMedia>
    //                     <CardContent sx={{ padding: 3 }}>
    //                         <Typography variant="h7" gutterBottom>
    //                             We are reading your CSV file and inserting into the database. If there is any issue with the data, Detailed report is sent to {email}.
    //                         </Typography>
    //                         <Box display="flex" alignItems="center" mb={1}>
    //                             <Typography sx={{ color: '#10375C', fontWeight: 'bold' }}>Success Count:</Typography>
    //                             <Typography sx={{ color: 'green', fontWeight: 'bold', ml: 1 }}>{sucessCount}</Typography>
    //                         </Box>
    //                         <Box display="flex" alignItems="center">
    //                             <Typography sx={{ color: '#10375C', fontWeight: 'bold' }}>Fail Count:</Typography>
    //                             <Typography sx={{ color: 'red', fontWeight: 'bold', ml: 1 }}>{failedCount}</Typography>
    //                         </Box>
    //                     </CardContent>
    //                 </Card>
    //             </Grid>
    //         </Grid>

    //         <Snackbar
    //             open={isDataSegmentSelected}
    //             autoHideDuration={5000}
    //             onClose={() => setIsDataSegmentSelected(false)}
    //             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //             sx={{ mt: 6 }}
    //         >
    //             <Alert onClose={() => setIsDataSegmentSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
    //                 Please Select a Data Segment
    //             </Alert>
    //         </Snackbar>

    //         <Snackbar
    //             open={isFileSelected}
    //             autoHideDuration={5000}
    //             onClose={() => setIsFileSelected(false)}
    //             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //             sx={{ mt: 6 }}
    //         >
    //             <Alert onClose={() => setIsFileSelected(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
    //                 Please select a CSV file to upload with ( , ) comma delimiter format
    //             </Alert>
    //         </Snackbar>

    //         <Snackbar
    //             open={isCsvFile}
    //             autoHideDuration={5000}
    //             onClose={() => setIsCsvFile(false)}
    //             anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //             sx={{ mt: 6 }}
    //         >
    //             <Alert onClose={() => setIsCsvFile(false)} severity="warning" variant="filled" sx={{ width: '100%' }} >
    //                 Uploaded file should be CSV file.
    //             </Alert>
    //         </Snackbar>
    //     </Box>
    // );
};
export default ReportingOverridesUpload;
