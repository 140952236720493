import axios from "axios";
import { getToken } from "../../../TokenAPI.js";
const OVERRIDES_POPULATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/reporting_overrides/populate`;
const OVERRIDES_SAVE_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/reporting_overrides/save`;

export async function overridesPopulateApi(requestBody) {
  const bearerToken = await getToken();
  return axios.post(OVERRIDES_POPULATE_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
}

export async function overridesSaveApi(requestBody) {
  const bearerToken = await getToken();
  return axios.post(OVERRIDES_SAVE_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
}
