import { useEffect, useState } from "react";
import { getDataSegmentIntFromString, getDataSegmentsDict } from "../../../Home/dataSegmentApi";
import { Alert, Box, Button, LinearProgress, MenuItem, Select, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from "@mui/icons-material/Cancel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatYearMonth } from "../../../helperFunctions";
import { parametersDownloadApi } from "./paramatersDownloadApi";
import Papa from 'papaparse';


export const ParametersDownload = ({switchTabs}) => {

    const [dataSegments, setDataSegments] = useState({});
    const [forceCapTypeOptions, setForceCapTypeOptions] = useState([
        'PROVIDER',
        'MEMBER',
        'CUSTOMER',
        'BENEFIT FACTOR'
    ])

    const [selectedDataSegment, setSelectedDataSegment] = useState('All');
    const [selectedForceCapType, setSelectedForceCapType] = useState('All');
    const [selectedStart, setSelectedStart] = useState();
    const [selectedEnd, setSelectedEnd] = useState();

    const [loading, setLoading] = useState(false);
    const [uiMessages, setUiMessages] = useState([]);

    useEffect(() => {
        setDataSegments(getDataSegmentsDict());
    }, [])

    function handleDownload() {
        setUiMessages([]);
        if (selectedDataSegment === 'select') {
            setUiMessages([['warning', 'No records found']]);
        } else {
            setLoading(true);
            var requestBody = {
                "DATA_SEG_ID": selectedDataSegment === 'All' ? '' : getDataSegmentIntFromString(selectedDataSegment),
                "FRC_CAP_TYP_CD": selectedForceCapType === 'All' ? '' : selectedForceCapType,
                "PROC_YR_MO": formatYearMonth(selectedStart),
                "PROC_YR_MO_END": formatYearMonth(selectedEnd)
            }
            console.log(requestBody);
            parametersDownloadApi(requestBody).then(response => {
                console.log(response);
                // Convert the response data to CSV
                const fields = [
                    'FRC_CAP_ID',
                    'MTCN',
                    'FRC_CAP_TYP_CD',
                    'REPROC_CD',
                    'DATA_SEG_ID',
                    'CONTR_ID',
                    'PRDCT_RDY_CD',
                    'CONTR_LN_ID',
                    'MBR_SRC_SYS_CD',
                    'CUST_ID',
                    'MBR_ID',
                    'MBR_ID_SUFX',
                    'COV_SRC_SYS_CD',
                    'POL_ID',
                    'CAP_BEN_PLN_ID',
                    'CMS_CONTR_ID',
                    'PLN_BEN_PCK_ID',
                    'PBP_SEG_ID',
                    'PCP_DIV_CD',
                    'PCP_PNL_ID',
                    'PCP_NTWK_ID',
                    'CAP_PRD_BEGN_YR_MO',
                    'CAP_PRD_END_YR_MO',
                    'PROC_YR_MO',
                    'CONTR_CHG_TYP_CD',
                    'DEL_CD',
                    'UPDT_USER_ID',
                    'UPDT_DTTM',
                ]
                const csv = Papa.unparse({
                    fields: fields,
                    data: response.data
                });

                // Create a Blob with the CSV data and download it
                const blob = new Blob([csv], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'data.csv';
                link.click();
                URL.revokeObjectURL(url);
                setLoading(false);
                setUiMessages([['success', 'Download successful']])
            }).catch(error => {
                setLoading(false);
                setUiMessages([['warning', 'No records found']]);
            })
        }
    }

    return (
        <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ width: 150, alignContent: 'center' }}>
                    <Typography variant='body3'>Data Segment</Typography>
                </Box>
                <Select
                    value={selectedDataSegment}
                    size='small'
                    onChange={(event) => setSelectedDataSegment(event.target.value)}
                    sx={{ width: 130 }}
                >
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value='select'>--Select--</MenuItem>
                    {Object.values(dataSegments).sort().map((value, index) => (
                        <MenuItem value={value} key={index}>{value}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ width: 150, alignContent: 'center' }}>
                    <Typography variant='body3'>Force Cap Type</Typography>
                </Box>
                <Select
                    value={selectedForceCapType}
                    size='small'
                    onChange={(event) => setSelectedForceCapType(event.target.value)}
                    sx={{ width: 180 }}
                >
                    <MenuItem value='All'>All</MenuItem>
                    {forceCapTypeOptions.map((option, index) => (
                        <MenuItem value={option} key={index}>{option}</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ width: 150, alignContent: 'center' }}>
                    <Typography variant='body3'>Process Month</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month']}
                            format="YYYY MM"
                            label="Start date"
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false
                                },
                            }}
                            sx={{ width: 150 }}
                            value={selectedStart}
                            onChange={(newValue) => setSelectedStart(newValue)}
                        />
                        <Typography variant='body3' sx={{ width: 50, textAlign: 'center', alignContent: 'center' }}>to</Typography>
                        <DatePicker
                            views={['year', 'month']}
                            format="YYYY MM"
                            label="End date"
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false
                                },
                            }}
                            sx={{ width: 150 }}
                            value={selectedEnd}
                            onChange={(newValue) => setSelectedEnd(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', mt: 1 }}>
                <Button
                    variant='contained'
                    startIcon={<FileDownloadIcon />}
                    sx={{ mr: 1 }}
                    onClick={() => handleDownload()}
                    disabled={loading}
                >
                    Download
                </Button>
                <Button
                    variant='contained'
                    color='error'
                    startIcon={<CancelIcon />}
                    onClick={() => switchTabs(0)}
                >
                    Cancel
                </Button>
            </Box>
            <Box sx={{ width: 500, mt: 2 }}>
                {uiMessages.map((message, index) => {
                    if (message[0] === 'error') {
                        return <Alert severity='error' size='small' key={'alert_' + index}>{message[1]}</Alert>
                    } else if (message[0] === 'warning') {
                        return <Alert severity='warning' size='small' key={'alert_' + index}>{message[1]}</Alert>
                    } else if (message[0] === 'success') {
                        return <Alert severity='success' size='small' key={'alert_' + index}>{message[1]}</Alert>
                    }
                })}
            </Box>
            <Box sx={{ width: '100%', mt: 2 }}>
                {loading && <LinearProgress />}
            </Box>
        </Box>
    )
};
export default ParametersDownload;
