import { Box, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import useSortConfig from '../../../sort_config.js';

export const SearchTable = ({
    inputTableData,
    handleInvoiceClick
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const { sortConfig, SortButton } = useSortConfig();

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (inputTableData.length > 0) {
            setTableData(inputTableData);
        }
    }, [inputTableData]);

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    return (
        <Paper sx={{ boxShadow: 'none', height:'60px' }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader>
                    <TableHead style={{ backgroundColor: '#b0c0da'}}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Data Segment</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Type</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>
                            <SortButton label="Process Month" sortKey="PROC_YR_MONTH" start='ascending' />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>
                            <SortButton label="Contract Owner MPIN" sortKey="CONTRACT_OWNER_MPIN" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>
                            <SortButton label="Contract#" sortKey="CONTRACT_NUMBER" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>
                            <SortButton label="Contract Line#" sortKey="CONTRACT_LN_ID" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>
                            <SortButton label="PA" sortKey="PA" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>
                            <SortButton label="Member ID" sortKey="MEMBER_ID" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>
                            <SortButton label="Invoice#" sortKey="INVOICE_NUMBER" />
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}><Box sx={{ whiteSpace:'nowrap' }}>Pay Now /</Box>Reverse</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Amount</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}><Box sx={{ whiteSpace:'nowrap' }}>Prepared /</Box><Box sx={{ whiteSpace:'nowrap' }}>Reviewed /</Box>Approved By</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da', whiteSpace: 'nowrap' }}>Status</TableCell>
                        <TableCell style={{ backgroundColor: '#b0c0da' }}>Adjustment code</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                        <TableRow key={index}>
                            <TableCell>{row.DATA_SEGMENT}</TableCell>
                            <TableCell>{row.TYPE}</TableCell>
                            <TableCell>{row.PROC_YR_MONTH}</TableCell>
                            <TableCell>{row.CONTRACT_OWNER_MPIN}</TableCell>
                            <TableCell>{row.CONTRACT_NUMBER}</TableCell>
                            <TableCell>{row.CONTRACT_LN_ID}</TableCell>
                            <TableCell>{row.PA}</TableCell>
                            <TableCell>{row.MEMBER_ID === "None" ? '--': row.MEMBER_ID}</TableCell>
                            <TableCell><a href='#' onClick={() => handleInvoiceClick(row)}>{row.INVOICE_NUMBER}</a></TableCell>
                            <TableCell>{row['PAYNOW/REVERSE']}</TableCell>
                            <TableCell>{row.AMOUNT}</TableCell>
                            <TableCell>{row.ENTERED} / {row.REVIEWED} / {row.APPROVED}</TableCell>
                            <TableCell>{row.STATUS}</TableCell>
                            <TableCell>{row.ADJUSTMENT_CODE}</TableCell>
                            </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                },
            }}
        />
    </Paper>
);
};
