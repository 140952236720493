  import axios from 'axios';  
  import { getToken } from "../../../TokenAPI";
  const MEMBER_HISTORY_MEMBERINFO_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/member-info`;
// SearchHandler.js  
export const MemberInfoApi = async (  
  CONTR_LN_ID,DATA_SEG_ID, ECAP_MBR_KEY,CUST_ID
  
) => {  
  // Prepare request body  
  const requestBody = {  
    member_partition_id:DATA_SEG_ID,
    data_segment: DATA_SEG_ID,   
    ecap_mbr_key: ECAP_MBR_KEY,
    CUST_ID: CUST_ID  
  };  
  const bearerToken = await getToken();
  

  return axios.post(  
    MEMBER_HISTORY_MEMBERINFO_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
        'Authorization': `Bearer ${bearerToken}`
      },  
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
}; 
