import Button from '@mui/material/Button';
import { openPrinterFriendly } from './printerFriendly';
import HelpButton from './helpButton.js';
export const HelpButtons = ( {index} ) => {
    console.log("index is", index);
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <HelpButton index={index} />
            <Button style={{ textTransform: 'none' }} onClick={() => openPrinterFriendly('reportsMainTable')}>
                Printer Friendly
            </Button>
        </div>
    );
}