import Button from '@mui/material/Button'
import { IconButton, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from "@mui/material/TableFooter";
import * as React from 'react';
import * as Constants from "./TotalCapConstants";
//import axios from "axios";
import { useState, useEffect } from "react";
import { MemberTotalCapApi } from "./MemberTotalCapApi";
import { CloudDownload } from "@mui/icons-material";

// Initialize the cache outside of the component  
const apiCache = new Map();

const MemberTotalCap = ({ memberData }) => {

    const [providerHistory, setProviderHistory] = useState([]);
    const [totals, setTotals] = useState({});

    const handleDownload = () => {

        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'totalCap_data.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        // Check if memberData is available and use its properties for the API call
        if (memberData) {
            const { DATA_SEG_ID, ECAP_MBR_KEY, ECAP_CONTR_LN_KEY, PROC_YR_MO, PAY_PARTN_ID } = memberData;
            const cacheKey = `${DATA_SEG_ID}-${ECAP_CONTR_LN_KEY}-${ECAP_MBR_KEY}-${PROC_YR_MO}-${PAY_PARTN_ID}`;

            console.log("Navigating to TotalCap with DATA_SEG_ID:", DATA_SEG_ID);
            console.log("Navigating to TotalCap with PROC_YR_MO:", PROC_YR_MO);
            console.log("Navigating to TotalCap with CAP_PRD_YR_MO:", PAY_PARTN_ID);
            console.log("Navigating to TotalCap with ECAP_MBR_KEY:", ECAP_MBR_KEY);
            console.log("Navigating to TotalCap with ECAP_CONTR_LN_KEY:", ECAP_CONTR_LN_KEY);

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data  
                const cachedData = apiCache.get(cacheKey);
                setProviderHistory(cachedData.Rows);
                setTotals(cachedData.TOTAL);
            } else {

                MemberTotalCapApi(DATA_SEG_ID, ECAP_MBR_KEY, ECAP_CONTR_LN_KEY, PROC_YR_MO, DATA_SEG_ID)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data);
                        // Check if the response has the Rows property  
                        if (response.data && response.data.Rows) {
                            setProviderHistory(response.data.Rows);
                        }
                        // Check if the response has the TOTAL property  
                        if (response.data && response.data.TOTAL) {
                            setTotals(response.data.TOTAL);
                        }
                    })
                    .catch(error => {
                        console.error('There was an error fetching the provider history:', error);
                    });
            }
            console.log(providerHistory);
        }
    }, [memberData]); // The empty array ensures this effect runs only once after the initial render

    const formatCurrency = (value) => {
        return `$${Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };
    const footerData = {
        OWNR_PROV_ID: "",
        CONTR_ID: "",
        CONTR_LN_ID: "",
        ASSOC_CONTR_ID: "",
        PROC_YR_MO: "",
        CAP_PRD_YR_MO: "Total",
        STD_SRVC_ADD_AMT: formatCurrency(totals.Add),
        STD_SRVC_TERM_AMT: formatCurrency(totals.Delete),
        CALCULATED_FIELD_1: formatCurrency(totals.EIGHT),
        CALCULATED_FIELD_2: formatCurrency(totals.NINE),
        TOTAL_ADJ_AMT: formatCurrency(totals.TEN),
        TOTAL_CAP: formatCurrency(totals.TOTAL_CAP),
    };

    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>


            <div style={{ marginTop: "1rem", width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleDownload}
                        id='download-button'
                    >
                        Download
                    </Button>
                </div>
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                <Constants.StyledTableCell align="center">Contract Owner MPIN</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Contract#</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Contract Line#</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Assoc Group</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Process Month</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Cap Period</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Add</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Delete</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Demo</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Std Svc Amount</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Adj Amount</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="center">Total Cap</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>


                        <Constants.StripedTableBody>

                            {providerHistory.map((provider, index) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell align="center">{provider.OWNR_PROV_ID}</TableCell>
                                    <TableCell align="center">{provider.CONTR_ID}</TableCell>
                                    <TableCell align="center">{provider.CONTR_LN_ID}</TableCell>
                                    <TableCell align="center">{provider.ASSOC_CONTR_ID}</TableCell>
                                    <TableCell align="center">{provider.PROC_YR_MO}</TableCell>
                                    <TableCell align="center">{provider.CAP_PRD_YR_MO}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '3rem' }}>{`$${parseFloat(provider.STD_SRVC_ADD_AMT).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '3rem' }}>{`$${parseFloat(provider.STD_SRVC_TERM_AMT).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '3rem' }}>{`$${parseFloat(provider.CALCULATED_FIELD_1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '2rem' }}>{`$${parseFloat(provider.CALCULATED_FIELD_2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '3rem' }}>{`$${parseFloat(provider.TOTAL_ADJ_AMT).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: '2rem' }}>{`$${parseFloat(provider.TOTAL_CAP).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</TableCell>
                                </TableRow>
                            ))}

                        </Constants.StripedTableBody>
                        <TableFooter>
                            <TableRow className="table-light" sx={{ borderLeftStyle: 'solid', borderLeftWidth: '0.1em' }} align="left">
                                {Object.values(footerData).map((value, index) => (
                                    <td style={{ textAlign: 'center' }} key={index}><strong>{value}</strong></td>
                                ))}
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                {/* </Paper> */}
            </div>
        </div>
    );
};

export default MemberTotalCap;
