import axios from "axios";
import { getToken } from "../../../TokenAPI.js";
const PARAMETERS_UPLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/force_cap/upload`;

export const parametersUploadApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(PARAMETERS_UPLOAD_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};
