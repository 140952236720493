import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { get, set } from 'react-hook-form';

// INSTRUCTIONS BELOW

const useSortConfig = () => {
    
    const [sortConfig, setSortConfig] = useState({});

    const processData = (tableRows, sortConfig) => {
		const groupedData = tableRows.reduce((acc, item) => {
			const group = item.PROC_YR_MO;
			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(item);
			return acc;
		}, {});
	
		const sortedGroups = Object.keys(groupedData).sort((a, b) => {
			for (const column in sortConfig) {
				if (sortConfig[column] !== 'default') {
					const aValue = a;
					const bValue = b;
					if (sortConfig[column] === 'ascending') {
						if (aValue > bValue) return 1;
						if (aValue < bValue) return -1;
					} else if (sortConfig[column] === 'descending') {
						if (aValue < bValue) return 1;
						if (aValue > bValue) return -1;
					}
				}
			}
			return 0;
		});
	
		return sortedGroups.map((group) => ({
			groupName: group,
			members: groupedData[group],
		}));
	};

    const getSortIcon = (column) => {
        if (sortConfig[column] === 'ascending') {
            return '/up_filled.png';
        } else if (sortConfig[column] === 'default') {
            return '/default_filled_2.png';
        }
        return '/down_filled.png';
    };

    const handleSort = (column) => {
        console.log("sorting")
        let direction = 'ascending';
        if (sortConfig[column] === 'ascending') {
            direction = 'descending';
        }

        setSortConfig((prevConfig) => {
            const newConfig = { ...prevConfig };
            for (const key in newConfig) {
                if (key !== column) {
                    newConfig[key] = 'default';
                }
            }
            newConfig[column] = direction;
            return newConfig;
        });
    };

    const initializeSortConfig = (sortKey, start) => {
        setSortConfig((prevConfig) => ({
            ...prevConfig,
            [sortKey]: start
        }));
    };

    const SortButton = ({ label, sortKey, start = 'default' }) => {
        useEffect(() => {
            if (!sortConfig[sortKey]) {
                initializeSortConfig(sortKey, start);
            }
        }, [sortKey, start, initializeSortConfig]);
        
        return (
            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                {label}
                <button onClick={() => handleSort(sortKey)} style={{ minWidth: '20px', padding: '0', marginLeft: '5px', background: 'none', border: 'none' }}>
                    <img src={getSortIcon(sortKey)} alt="Sort" style={{ width: '12px', height: '12px' }} />
                </button>
            </span>
        );
    };

    return { sortConfig, getSortIcon, handleSort, processData, SortButton };
};

export default useSortConfig;




{/* 

//INSTRUCTIONS


<TableCell style={{ backgroundColor: '#b0c0da' }}>
    <SortButton label="title of column" sortKey="table row id" start='ascending' />
</TableCell>

******** replace tablecell block with this block where you want to add the sorting functionality
******** mark start as 'ascending' if first value and want to have the starting position as ascending
******** all other values should be left without a start value

const sortedData = [...tableData].sort((a, b) => {
    for (const column in sortConfig) {
        if (sortConfig[column] !== 'default') {
            const aValue = a[column];
            const bValue = b[column];
            if (sortConfig[column] === 'ascending') {
                if (aValue > bValue) return 1;
                if (aValue < bValue) return -1;
            } else if (sortConfig[column] === 'descending') {
                if (aValue < bValue) return 1;
                if (aValue > bValue) return -1;
            }
        }
    }
    return 0;
});

******** print this block and change tableData to sortedData in the map function
******** this will sort the data based on the column and direction selected


const { sortConfig, SortButton } = useSortConfig();
******** add to the top of the file

******** make sure to import the useSortConfig function from the correct path

*/}