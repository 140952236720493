import { Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Constants from "./AdjustmentsConstants";
import { MemberAdjustmentsApi } from "./MemberAdjustmentsApi";
import React, { useState, useEffect } from 'react';
import useSortConfig from '../../../sort_config.js';

// Initialize the cache outside of the component  
const apiCache = new Map();

const MemberAdjustments = ({ memberData }) => {

    const [AdjustmentsData, setAdjustmentsData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { sortConfig, SortButton } = useSortConfig();


    const formatMemberResponse = (value) => {
        if (value === null || value === 'None' || value.trim() === '') {
            return '$0.00';
        } else {
            // Assuming value is a number or a string that can be converted to a number  
            // Format the number as a currency string with 2 decimal places  
            const numberValue = parseFloat(value);
            return `$${numberValue.toFixed(2)}`;
        }
    };


    const handleDownload = () => {

        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'adjustments.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    useEffect(() => {
        if (memberData) {
            const { DATA_SEG_ID, ECAP_CONTR_LN_KEY, ECAP_MBR_KEY, PROC_YR_MO, PAY_PARTN_ID, cap_pay_typ_cd } = memberData;
            const cacheKey = `${DATA_SEG_ID}-${ECAP_CONTR_LN_KEY}-${ECAP_MBR_KEY}-${PROC_YR_MO}-${PAY_PARTN_ID}-${cap_pay_typ_cd}`;

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data  
                setAdjustmentsData(apiCache.get(cacheKey));
            } else {
                MemberAdjustmentsApi(DATA_SEG_ID, ECAP_CONTR_LN_KEY, ECAP_MBR_KEY, PROC_YR_MO, cap_pay_typ_cd)
                    .then(response => {
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data);
                        if(response.data.length > 0) {
                            setAdjustmentsData(response.data);
                        } else {
                            setErrorMessage('No Record Found');
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching coverage data:", error);
                    });
            }
        }
    }, [memberData]);


    const sortedData = [...AdjustmentsData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });


    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {memberData.DATA_SEG_ID} | <b>{`${memberData.LST_NM}, ${memberData.FST_NM}`}</b>|
                Member ID:
                {`${memberData.CUST_ID}-${memberData.MBR_ID}-${memberData.MBR_ID_SUFX}`} | Arrangement: {memberData.PROV_ARNG_CD}| Cap Contract Type: {memberData.CONTR_TYP_CD}
            </Typography>

            <div style={{ marginTop: "1rem", width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					onClick={handleDownload}
					id='download-button'
				>
					Download
				</Button>
			</div>

                <Paper sx={{ width: '98.5vw' }}>
                    <TableContainer sx={{ maxHeight: 550, marginRight: -5 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>

                                <TableRow>
                                    <Constants.StyledTableCell>Contract Owner MPIN</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Contract#</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Contract line#</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Assoc Group </Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Cap Process Month</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Cap Period</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>
                                        <SortButton label="Adj Category" sortKey="ADJ_CATGY_CD" start='ascending'/>
                                    </Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Adj Rate Type</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>
                                        <SortButton label="Adj / Bene Code" sortKey="ADJ_CD" />
                                    </Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Percent/Dollar</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Base Rate</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Mbr/Emp Prem Amt</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Prem Adj Amt</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Gross Cap</Constants.StyledTableCell>
                                    <Constants.StyledTableCell>Adj Amount</Constants.StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <Constants.StripedTableBody>
                                {sortedData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.OWNR_PROV_ID}</TableCell>
                                        <TableCell>{item.CONTR_ID}</TableCell>
                                        <TableCell>{item.CONTR_LN_ID}</TableCell>
                                        <TableCell>{item.ASSOC_PROV_ID}</TableCell>
                                        <TableCell>{item.PROC_YR_MO}</TableCell>
                                        <TableCell>{item.CAP_PRD_YR_MO}</TableCell>
                                        <TableCell>{item.ADJ_CATGY_CD}</TableCell>
                                        <TableCell>{item.ADJ_TYP_CD}</TableCell>
                                        <TableCell>{item.ADJ_CD}</TableCell>
                                        <TableCell>{item.dolprc}</TableCell>
                                        <TableCell sx={{ textAlign:'right', paddingRight:'2rem' }}>{formatMemberResponse(item.ADJ_AMT)}</TableCell>
                                        <TableCell sx={{ textAlign:'right', paddingRight:'2rem' }}>{formatMemberResponse(item.ADJ_PREM_AMT)}</TableCell>
                                        <TableCell sx={{ textAlign:'right', paddingRight:'2rem' }}>{formatMemberResponse(item.PREM_ADJ_AMT)}</TableCell>
                                        <TableCell sx={{ textAlign:'right', paddingRight:'2rem' }}>{formatMemberResponse(item.BAS_AMT)}</TableCell>
                                        <TableCell sx={{ textAlign:'right', paddingRight:'2rem' }}>{formatMemberResponse(item.CAP_AMT)}</TableCell>
                                    </TableRow>
                                ))}
                            </Constants.StripedTableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </div>
            {errorMessage && (<div style={{width:'100%', textAlign:'center', color:'red'}}>{errorMessage}</div>)}
        </div>
    );
};

export default MemberAdjustments;
