import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button'; 
import useSortConfig from '../../sort_config.js';


export const MonthlyProcessedTable = ({
    inputTableData,
    setRenderChoice,
    adjustGroupData
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const { sortConfig, SortButton } = useSortConfig();


    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function errCodeClick(code, severity, message) {
        // can put both of these into a helper function
        adjustGroupData('ERR_TYP_CD', code);
        adjustGroupData('ERR_SEV_CD', severity);
        adjustGroupData('LNG_DESC', message);
        setRenderChoice(1);
    }

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead style={{ backgroundColor: '#b0c0da' }}>
                        <TableRow style={{ backgroundColor: '#b0c0da' }}>
                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                <SortButton label="Error Code" sortKey="ERR_TYP_CD" start='ascending'/>
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Category</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                <SortButton label="Error Severity" sortKey="ERR_SEV_CD"/>
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Message</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                <SortButton label="Count" sortKey="CNT" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow key={row.ERR_TYP_CD}>
                                        <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => errCodeClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC)}>{row.ERR_TYP_CD}</a></TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.JOB_TYP_CD}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_SEV_CD}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.LNG_DESC}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CNT}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '.MuiTablePagination-selectLabel': {
                        paddingTop: '12px',
                    },
                    '.MuiTablePagination-displayedRows': {
                        paddingTop: '13px',
                    },
                }}
            />
        </Paper>
    );
};
export default MonthlyProcessedTable;