import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { MonthlyListApi } from './MonthlyProcessedApi';
import { getMonthlyList, setMonthlyList } from '../errorPersistence';

export const MonthlyList = ({
    setRenderChoice,
    inputListData,
    adjustDetailData
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');
        if (!table) {
            console.error('Table not found');
            return;
        }
        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });
        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');
        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });
        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'MonthlyDetail.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        setErrorMessage('');
        var temp = getMonthlyList();
        if (temp) {
            setTableData(temp);
            setLoadingTable(false);
            setShowTable(true);
        } else {
            MonthlyListApi(inputListData).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    setTableData(response.data);
                    setMonthlyList(response.data);
                    setShowTable(true);
                    adjustDetailData('DATA_SEG_ID', inputListData['DATA_SEG_ID']);
                    adjustDetailData('JOB_TYP_CD', inputListData['JOB_TYP_CD']);
                    adjustDetailData('PROC_YR_MO', inputListData['PROC_YR_MO']);
                    adjustDetailData('CYC_RUN_SEQ_NBR', inputListData['CYC_RUN_SEQ_NBR']);
                    adjustDetailData('ERR_TYP_CD', inputListData['ERR_TYP_CD']);
                    adjustDetailData('ERR_SEV_CD', inputListData['ERR_SEV_CD']);
                    adjustDetailData('LNG_DESC', inputListData['LNG_DESC']);
                    adjustDetailData('ERR_DATA_VAL', inputListData['ERR_DATA_VAL'])
                    adjustDetailData('JOB_ID', inputListData['JOB_ID']);
                    adjustDetailData('PROC_YR_MO', inputListData['PROC_YR_MO']);
                    adjustDetailData('CAP_PRD_YR_MO', inputListData['CAP_PRD_YR_MO']);
                    adjustDetailData('CREAT_DTTM', inputListData['CREAT_DTTM']);
                } else {
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                console.log(error);
                setLoadingTable(false);
                setErrorMessage('No data found');
            });
        }
    }, [])

    function monthlyListClick(mpin, id, lineId) {
        adjustDetailData('CORP_MPIN', mpin);
        adjustDetailData('CONTR_ID', id);
        adjustDetailData('CONTR_LN_ID', lineId);
        setRenderChoice(3);
    }

    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputListData['DATA_SEG_ID'])} | Error Severity: {inputListData['ERR_SEV_CD']} | Error Category: {inputListData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputListData['ERR_TYP_CD']} | Error Message: {inputListData['LNG_DESC']} | Error Value: {inputListData['ERR_DATA_VAL']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Monthly Processed Search Results</a> {'>'} <a href='#' onClick={() => setRenderChoice(1)}>Error Group by Value</a> {'>'} Error Detail List
            </Typography>

            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Source System</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Member ID</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Corp MPIN</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Type</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Benefit Plan ID</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Employer#</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.POL_ID}-{row.MBR_ID}-{row.MBR_ID_SUFX}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CONTR_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CONTR_LN_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}><a href='#' onClick={() => monthlyListClick(row.CORP_MPIN, row.CONTR_ID, row.CONTR_LN_ID)}>{row.CORP_MPIN}</a></TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CONTR_TYP_CD}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CAP_BEN_PLN_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.POL_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.COMMT}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%', mt: '1rem' }}>
                    <LinearProgress />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: '1rem' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
