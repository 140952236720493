import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { contractNormalApi } from "./contractNormalApi";
import { getContractContractResults, storeContractContractResults } from '../providerContractPersistence';
import { yyyymmddToMmddyyyy } from '../../../helperFunctions';

export const ProviderContractRegular = ({
	searchLink,
	lineLink,
	inputContractName,
	inputContractEffDate,
	inputContractNumber,
	inputPublicPri,
	selectedDataSegmentString,
	setPublicSelectedDataSegment
}) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tableData, setTableData] = useState([]);
	const [contractName, setContractName] = useState('');
	const [contractEffDate, setContractEffDate] = useState('');
	const [contractNumber, setContractNumber] = useState('');
	const [pri, setPri] = useState();

	useEffect(() => {
		if (inputContractName) {
			setContractName(inputContractName);
		}
	}, [inputContractName]);

	useEffect(() => {
		if (inputContractEffDate) {
			setContractEffDate(inputContractEffDate);
		}
	}, [inputContractEffDate]);

	useEffect(() => {
		if (inputContractNumber) {
			setContractNumber(inputContractNumber);
		}
	}, [inputContractNumber]);

	useEffect(() => {
		if (inputPublicPri) {
			setPri(inputPublicPri);
		}
	}, [inputPublicPri]);

	useEffect(() => {
		// if pri isn't null, do search. run this whenever pri changes
		if (pri) {
			const requestBody = {
				"PRDCT_RDY_CD": pri,
				"CONTR_ID": contractNumber,
				"CONTR_LN_EFF_DT": contractEffDate
			}
			contractNormalApi(requestBody).then(response => {
				var temp = response.data;
				try {
					temp.sort(function (first, second) {
						return Number(first.contract_line_eff_date.split('-').join('')) - Number(second.contract_line_eff_date.split('-').join(''));
					});
					setTableData(temp);
					storeContractContractResults(response.data);
					if (response.data.length > 0) {
						setPublicSelectedDataSegment(response.data[0].data_segment);
					}
				} catch {
					console.log("Error sorting provider contract regular array on contract_line_eff_date");
				}
			}).catch(error => {
				console.log("There was an error fetching contract line data from api 1");
			});
		}
	}, [pri]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	function handleRowClick(data_segment, contractLineId, contractTypeCd, calcMethCd, contractLineEffDate, contractLineEndDate) {
		lineLink(data_segment, contractLineId, contractTypeCd, calcMethCd, contractLineEffDate, contractLineEndDate);
	}

	// session storage stuff
	useEffect(() => {
		var temp = getContractContractResults();
		if (temp) {
			setTableData(temp);
		}
	}, []);

	return (
		<div>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Data Segment: {selectedDataSegmentString} | Contract Name: {contractName} | Contract Eff Date: {yyyymmddToMmddyyyy(contractEffDate)}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Contract#: {contractNumber}
			</Typography>
			<Typography sx={{ fontSize: "0.875rem" }}>
				Back to: <a href='#' onClick={() => searchLink()}>Contract Search Results</a> {">"} Contract
			</Typography>
			{tableData.length > 0 ?
				<Paper sx={{ mt: 2 }}> {/* Added space on top of the table */}
					<TableContainer sx={{ maxHeight: 800 }}>
						<Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
							<TableHead style={{ backgroundColor: '#b0c0da' }}>
								<TableRow style={{ backgroundColor: '#b0c0da' }}>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Contract Line #</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Contract Type</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Calculation Method</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Contract Line Eff Date</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Contract Line End Date</TableCell>
									<TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da' }}>Prod Ready Indic</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<TableRow key={'contractData_' + index}>
												<TableCell style={{ whiteSpace: 'nowrap' }}><a href='#' onClick={() => handleRowClick(row.data_segment, row.contract_line_number, row.contract_type, row.calculation_method, row.contract_line_eff_date, row.contract_line_end_date)}>{row.contract_line_number}</a></TableCell>
												<TableCell style={{ whiteSpace: 'nowrap' }}>{row.contract_type}</TableCell>
												<TableCell style={{ whiteSpace: 'nowrap' }}>{row.calculation_method}</TableCell>
												<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.contract_line_eff_date)}</TableCell>
												<TableCell style={{ whiteSpace: 'nowrap' }}>{yyyymmddToMmddyyyy(row.contract_line_end_date)}</TableCell>
												<TableCell style={{ whiteSpace: 'nowrap' }}>{row.prod_ready_indic}</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={tableData.length} // Replace with the actual count of rows  
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							'.MuiTablePagination-selectLabel': {
								paddingTop: '12px',
							},
							'.MuiTablePagination-displayedRows': {
								paddingTop: '13px',
							},
						}}
					/>
				</Paper>
				:
				<LinearProgress sx={{ mt: 2 }} />}
		</div>
	);
};  
