import Box from '@mui/material/Box';
import React from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { useState, useEffect } from 'react';
import { Select, MenuItem, LinearProgress } from '@mui/material';
import { testPDSeg } from '../../testPopupDataSegment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AgingApi } from './AgingApi';
import AgingTable from "./AgingTable";
import { getDataSegments } from '../../Home/dataSegmentApi';
import { formatYearMonth } from '../../helperFunctions';
import { getAging, setAging } from '../errorPersistence';

const errorCategoryoptions = [
    'BENEFIT STRUCTURE',
    'CORE CAP',
    'MEMBER',
    'PAYMENT',
    'PROVIDER'
]

export const AgingNormal = ({
    setRenderChoice,
    adjustDetailData
}) => {
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegmentIds, setDataSegmentIds] = useState();
    const [dataSegments, setDataSegments] = useState(['']);
    const [errorCategory, setErrorCategory] = useState('BENEFIT STRUCTURE');
    const [processMonth, setProcessMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [errorCode, setErrorCode] = useState('');
    const [contractMPIN, setContractMPIN] = useState('');
    const [employerGroupNumber, setEmployerGroupNumber] = useState('')
    const [ssmk, setSsmk] = useState('');
    const [missingDataSegmentMessage, setMissingDataSegmentMessage] = useState(false);
    const [missingProcessMonthMessage, setMissingProcessMonthMessage] = useState(false);
    const [missingEndMonthMessage, setMissingEndMonthMessage] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [tableData, setTableData] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingTable, setLoadingTable] = useState(false);

    const dataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    }

    const handleErrorCategoryChange = (event) => {
        setErrorCategory(event.target.value);
    }

    function handleProcessMonthChange(date) {
        setProcessMonth(date);
        setEndMonth(date); 
    }
    function handleEndMonthChange(date) {
        setEndMonth(date);
    }

    const handleErrorCodeChange = (event) => {
        setErrorCode(event.target.value);
    }

    const handleContractMPINChange = (event) => {
        setContractMPIN(event.target.value.replace(/\D/g, ''));
    }

    const handleEmployerGroupNumber = (event) => {
        setEmployerGroupNumber(event.target.value);
    }

    function ssmkChange(event) {
        setSsmk(event.target.value);
    }

    const handleSubmit = () => {
        setShowTable(false);
        setErrorMessage('');
        var runQuery = true;
        if (dataSegment === '') {
            setMissingDataSegmentMessage(true);
            runQuery = false;
        } else {
            setMissingDataSegmentMessage(false);
        }
        if (!processMonth || isNaN(Date.parse(processMonth))) {
            setMissingProcessMonthMessage(true);
            runQuery = false;
        } else {
            setMissingProcessMonthMessage(false);
        }
        if (!endMonth || isNaN(Date.parse(endMonth))) {
            setMissingEndMonthMessage(true);
            runQuery = false;
        } else {
            setMissingEndMonthMessage(false);
        }
        if (runQuery) {
            setLoadingTable(true);
            var requestBody = {
                'DATA_SEG_ID': dataSegmentIds[dataSegment],
                'JOB_TYP_CD': errorCategory,
                'start': formatYearMonth(processMonth),
                'end': formatYearMonth(endMonth),
                'ERR_TYP_CD': errorCode,
                'PROV_ID': contractMPIN,
                'POL_ID': employerGroupNumber,
                'CUST_ID': '',
                'MBR_ID': '',
                'MBR_ID_SUFX': ''
            }
            var splitSsmk;
            if (ssmk !== undefined && ssmk !== null && ssmk !== '') {
                splitSsmk = ssmk.split('-');
                if (splitSsmk.length === 3) {
                    requestBody['CUST_ID'] = splitSsmk[0];
                    requestBody['MBR_ID'] = splitSsmk[1];
                    requestBody['MBR_ID_SUFX'] = splitSsmk[2];
                }
            }
            AgingApi(requestBody).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    setAging({
                        'data':response.data,
                        'filters':{
                            'DATA_SEG_ID': dataSegment,
                            'JOB_TYP_CD': errorCategory,
                            'ERR_TYP_CD': errorCode,
                            'PROV_ID': contractMPIN,
                            'POL_ID': employerGroupNumber,
                            'SSMK': ssmk
                        }
                    })
                    setTableData(response.data);
                    setShowTable(true);
                    adjustDetailData('DATA_SEG_ID', requestBody['DATA_SEG_ID']);
                    adjustDetailData('JOB_TYP_CD', requestBody['JOB_TYP_CD']);
                    adjustDetailData('Process_month', requestBody['start']);
                    adjustDetailData('End_Month', requestBody['end']);
                    adjustDetailData('PROV_ID', requestBody['PROV_ID']);
                    adjustDetailData('POL_ID', requestBody['POL_ID']);
                    adjustDetailData('CUST_ID', requestBody['CUST_ID']);
                    adjustDetailData('MBR_ID', requestBody['MBR_ID']);
                    adjustDetailData('MBR_ID_SUFX', requestBody['MBR_ID_SUFX']);
                } else {
                    setErrorMessage('No records found');
                }
            }).catch(error => {
                console.log(error);
                setLoadingTable(false);
                setErrorMessage('No records found')
            });
        }
    };

    const handleReset = () => {
        setDataSegment('');
        setErrorCategory('BENEFIT STRUCTURE');
        setProcessMonth(null);
        setEndMonth(null);
        setErrorCode('');
        setContractMPIN('');
        setEmployerGroupNumber('');
        setSsmk('');
        setShowTable(false);
        setMissingDataSegmentMessage(false);
        setMissingProcessMonthMessage(false);
        setMissingEndMonthMessage(false);
        setErrorMessage('');
    };

    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegmentIds(dataIds);
        setDataSegments(dataList);
    }, [])

    useEffect(() => {
        var temp = getAging();
        if (temp) {
            setTableData(temp['data']);
            setShowTable(true);
            // set filters
            temp = temp['filters'];
            setDataSegment(temp['DATA_SEG_ID']);
            setErrorCategory(temp['JOB_TYP_CD']);
            setErrorCode(temp['ERR_TYP_CD']);
            setContractMPIN(temp['PROV_ID']);
            setEmployerGroupNumber(temp['POL_ID']);
            setSsmk(temp['SSMK']);
        }
    }, [])

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: 315 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 315, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 137 }}>
                            <Select
                                size='small'
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                            >
                                {dataSegments.map((segment) => (
                                    <MenuItem
                                        value={segment}
                                        key={segment}
                                        name='data-segment-option'
                                    >
                                        {segment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Error Category<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <Select
                                id='error-Category'
                                size="small"
                                fullWidth
                                value={errorCategory}
                                onChange={(event) => handleErrorCategoryChange(event)}
                            >
                                {errorCategoryoptions.map((option) => (
                                    <MenuItem name='error-option' value={option} key={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Process Month <br /> From <span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => handleProcessMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={processMonth}
                                    value={processMonth}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Process Month to<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => handleEndMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={endMonth}
                                    value={endMonth}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ flex: 1, fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}> Error Code</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id="error-code"
                                onChange={(event) => handleErrorCodeChange(event)}
                                fullWidth
                                size="small"
                                multiline
                                value={errorCode}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract MPIN</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id="contract-mpin"
                                value={contractMPIN}
                                onChange={(event) => handleContractMPINChange(event)}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Employer Group#</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id="employer-group-number"
                                value={employerGroupNumber}
                                onChange={(event) => handleEmployerGroupNumber(event)}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Source System</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 1, whiteSpace: 'nowrap' }}>Member Key</Typography>
                        </div>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            {/* Reduced maxWidth to 200 */}
                            <TextField
                                required
                                id='source-system-member-key'
                                value={ssmk}
                                onChange={(event) => ssmkChange(event)}
                                fullWidth
                                size="small"
                            />
                        </Box>
                    </Box>
                    <Button
                        id='search-button'
                        size="medium"
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={() => handleSubmit()}
                    >
                        Search
                    </Button>
                </Box>
            }
            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {(missingDataSegmentMessage || missingProcessMonthMessage || missingEndMonthMessage) &&
                <div style={{ textAlign: 'center', width: '100%', color: 'red' }}>
                    {missingDataSegmentMessage &&
                        <div>
                            Data Segment is required.
                        </div>
                    }
                    {missingProcessMonthMessage &&
                        <div>
                            Process Month From is required.
                        </div>
                    }
                    {missingEndMonthMessage &&
                        <div>
                            Process Month To is required.
                        </div>
                    }
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ ml: '1rem', mt: '3.5rem' }} />
                </Box>
            }
            {showTable && (
                <div style={{ marginLeft: '1rem', width: '100%', overflowX: 'scroll', marginTop: '1.2rem' }}>
                    <AgingTable
                        setRenderChoice={setRenderChoice}
                        inputTableData={tableData}
                        adjustDetailData={adjustDetailData}
                    />
                </div>
            )}
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
                    {errorMessage}
                </div>
            )}
        </div>

    )
};
