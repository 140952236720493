export function openPrinterFriendly(pageID) {
    const componentElement = document.getElementById(pageID);
    console.log(componentElement);
    const newWindow = window.open('', 'new', 'width=600,height=400,resizable=yes,status=yes');
    newWindow.document.body.innerHTML = componentElement.innerHTML;
    setSelects(componentElement, newWindow.document);
}

function setSelects(oldPage, newPage) {
    const selectElementsOld = oldPage.querySelectorAll('select');

    var selections = [];

    for (const selectElement of selectElementsOld) {
        const selectedOption = selectElement.options[selectElement.selectedIndex];
        if (selectedOption) {
            selections.push(selectedOption.value);
        } else {
            selections.push(null);
        }
    }

    const selectElementsNew = newPage.querySelectorAll('select');

    let index = 0;

    for (const selectElement of selectElementsNew) {
        if (index < selectElementsNew.length) {
            if (selections[index] !== null) {
                selectElement.value = selections[index];
            }
            selectElement.disabled = true;
            index++;
        }
    }
}
