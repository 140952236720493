import React from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const AdminHelp = () => {
    return (
        <div>
            <Typography id="help-modal-title" variant="h6" component="h1" sx={{ textAlign: 'center', fontSize: '2rem' }}>
                Admin Help
            </Typography>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Screen Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Roles</TableCell>
                            <TableCell>Grant access to screens and set approval thresholds</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Codes</TableCell>
                            <TableCell>Codes used in ECap, their short and long descriptions</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Code Search</TableCell>
                            <TableCell>Search for existing Codes, display results and download values</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Code Add</TableCell>
                            <TableCell>Add new Codes with short and long descriptions</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Code Update</TableCell>
                            <TableCell>Update existing Codes with short and long descriptions</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Code Upload</TableCell>
                            <TableCell>Upload a file with new or existing Codes with short and long descriptions</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Group Contracts</TableCell>
                            <TableCell>Assign contract lines to a different model and/or cycle</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Exclude Contracts</TableCell>
                            <TableCell>Exclude contract lines from processing in their default model/cycle</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Approval</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />

            <Typography id="help-modal-title" variant="h6" component="h2">
                Roles
            </Typography>
            <Typography variant="body1" paragraph>
                Each ECap user is assigned a Role; each Role is granted access to specific ECap features.
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to change information in the Roles screens. Some of the more senior Cap Ops staff has the authority to view the roles information.
            </Typography>
            <Typography variant="body1" paragraph>
                Available roles are:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Administrator</li>
                <li>Non ECap</li>
                <li>ECap Associate</li>
                <li>ECap Analyst</li>
                <li>ECap Lead</li>
                <li>Supervisor</li>
                <li>Manager</li>
                <li>Director</li>
                <li>Senior Management</li>
            </Typography>
            <Typography variant="body1" paragraph>
                The access rights are:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>View: View screens. User cannot perform any other activity.</li>
                <li>Add: Add transactions and View screens.</li>
                <li>Update: Change existing data and View screens.</li>
                <li>Delete: Delete transactions and View screens.</li>
            </Typography>
            <Typography variant="body1" paragraph>
                Access rights are granted at the level of the seven primary screen choices and the submenus within them. View, add, update and delete authority can be assigned, by role, to each of the following screens or groups or screens:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Admin</li>
                <li>Roles</li>
                <li>Codes</li>
                <li>Group contracts</li>
                <li>Exclude contracts</li>
                <li>Maintenance</li>
                <li>Cap/payment adjustments</li>
                <li>Force cap parameter</li>
                <li>Retro period control</li>
                <li>Premium factors</li>
                <li>Benefit factors</li>
                <li>Member</li>
                <li>History</li>
                <li>Provider</li>
                <li>Contract</li>
                <li>Demographics</li>
                <li>Provider history</li>
                <li>Errors</li>
                <li>Job scheduling</li>
                <li>Reports</li>
                <br />
            </Typography>
            <Typography id="help-modal-title" variant="h6" component="h2">
            Codes
            </Typography>
            <Typography variant="body1" paragraph>
                ECap includes numerous codes for information such as adjustments, genders, calculation methods, and the like. The Codes screens provide access to this information.
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to add and change information in the Codes screens. Some of the more senior Cap Ops staff has the authority to view the codes information.
            </Typography>
            <Typography variant="body1" paragraph>
                Each type of information is identified by a Code Type (e.g., STATECODE for state codes, ADJCD for Adjustment Codes). Within each Code Type, the Code, Short Description and Long Description are available.
            </Typography>
            <Typography variant="body1" paragraph>
                Refer to &lt;Screen Notations&gt; for a description of the symbols on the screens.
            </Typography>
            <Typography id="help-modal-title" variant="h6" component="h2">
                Code Search
            </Typography>
            <Typography variant="body1" paragraph>
                Using the Search screen, you can look up:
                <ul>
                    <li>All entries for a Code Type</li>
                    <li>A single Code within a Code Type</li>
                    <li>Active only, Inactive only, All entries (active and inactive)</li>
                </ul>
                The Code Type is always required.
            </Typography>
            <Typography variant="body1" paragraph>
                You can download a list of displayed Codes into an Excel worksheet.
            </Typography>

            <Typography id="help-modal-title" variant="h6" component="h2">
                Code Add
            </Typography>
            <Typography variant="body1" paragraph>
                On the Code Add screen, you can:
                <ul>
                    <li>Add more Codes for an existing Code Type</li>
                    <li>You may add up to ten new Codes on one screen</li>
                    <li>Codes entered as lower-case letters are converted to all capital letters</li>
                    <li>You will not be allowed to add a Code if a matching Code already exists</li>
                    <li>Add a new Code Type</li>
                    <li>Code Types entered as lower-case letters are converted to all capital letters</li>
                    <li>You will be warned to consult with ECap IT Admin if you enter a new Code Type. A new Code Type has no meaning unless IT uses it in a program. So a new Code Type would not be added unless changes are being made to ECap.</li>
                </ul>
            </Typography>

            <Typography id="help-modal-title" variant="h6" component="h2">
                Code Update
            </Typography>
            <Typography variant="body1" paragraph>
                On the Code Update screen, you can:
                <ul>
                    <li>Change the Short and/or the Long Description for a Code</li>
                    <li>Change as many as ten entries on a screen at the same time</li>
                </ul>
                <strong>*** Warning ***</strong>
                <br />
                If you make a change then page to the next screen without clicking on Update, you will lose any changes you entered.
            </Typography>

            <Typography id="help-modal-title" variant="h6" component="h2">
                Code Upload
            </Typography>
            <Typography variant="body1" paragraph>
                The Code Upload process is designed for “bulk” uploads, i.e., for situations where manually adding entries through the Code Add screen is too laborious. The process is:
                <ul>
                    <li>Create a csv file in the correct format</li>
                    <li>Enter all the new Codes and their associated descriptions in the csv file</li>
                    <li>Use the Code Upload screen to add the entries in the csv file to ECap.</li>
                </ul>
                After the upload:
                <ul>
                    <li>A message is displayed with the number of successful or failed entries</li>
                    <li>An e-mail is sent to a Cap Ops mailbox declaring the success or failure</li>
                    <li>If any entries were rejected, the e-mail includes an Excel workbook listing the rejected entries and an error message for each entry.</li>
                </ul>
                <strong>*** Warnings ***</strong>
                <ul>
                    <li>If a Code in the upload file matches an existing Code, the upload values will replace the existing values. No message is created.</li>
                    <li>If the upload file has entries with duplicate Codes, the values for the second entry will overlay the values initially loaded for the first entry.</li>
                </ul>
            </Typography>

            <Typography id="help-modal-title" variant="h6" component="h2">
                Group Contracts
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to add and change information in the Group Contracts screen. Some of the more senior Cap Ops staff has the authority to view the Group Contracts information.
            </Typography>
            <Typography variant="body1" paragraph>
                The Group Contracts screen is used to:
                <ul>
                    <li>Permanently move a contract/contract line to a different model</li>
                    <li>Temporarily move a contract/contract line to a later processing cycle in the current month.</li>
                </ul>
                <em>Note: This functionality will not be operational in the first version of ECap: each data segment has only one model and no shifting of contract/contract line processing dates will occur.</em>
            </Typography>
            <Typography variant="body1" paragraph>
                Each data segment has at least one model. The purpose of additional models is to assign permanently a contract and contract line to a different processing date. This affects when membership is determined for capitation calculations.
            </Typography>
            <Typography variant="body1" paragraph>
                Creating a new model for a data segment is a significant event. The date is a configuration value that must be established by IT. If the assignment is of an existing contract/contract line, there are retro calculation implications that IT must address.
            </Typography>
            <Typography variant="body1" paragraph>
                Shifting a contract/contract line to a later processing date has implications for IT operations, since they must schedule the batch processing on the new date. These processes are not yet defined.
            </Typography>

            <Typography id="help-modal-title" variant="h6" component="h2">
                Exclude Contracts
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to add and change information in the Exclude Contracts screen. Some of the more senior Cap Ops staff has the authority to view the Exclude Contracts information.
            </Typography>
            <Typography variant="body1" paragraph>
                The Exclude Contracts screen is used to stop a contract/contract line from processing in the current month as specified by its model and cycle.
            </Typography>
            <Typography variant="body1" paragraph>
                <em>Note: This functionality will not be operational in the first version of ECap: no exclusion of contract/contract lines from processing will occur.</em>
            </Typography>
                </div>
            );
};

export default AdminHelp;