import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import TablePagination from '@mui/material/TablePagination';
import { formatDollars } from '../../../helperFunctions';
import Button from '@mui/material/Button'
import useSortConfig from '../../../sort_config.js';


function Row(props) {
	const { group, handleTabChange, onToggleExpand } = props;
	const [open, setOpen] = React.useState(false);
	const [currentTab, setCurrentTab] = useState('tab1');



	// Check if group.members is defined and has at least one element  
	if (!group.members || group.members.length === 0) {
		return null;  // or some default JSX if you prefer  
	}

	console.log("groups after sorted are", group);



	const row = { ...group.members[0] };

	row.CAP_PRD_YR_MO = "";

	console.log("row value for parent is when selected", row);


	console.log("row value when child is selected is", row);

	const sumProperties = ['CURRENT_MEMBER_COUNT', 'RETRO_MEMBER_COUNT', 'TOTAL_MEMBER_COUNT', 'CURRENT_STANDARD_SERVICE_CAP', 'RETRO_STANDARD_SERVICE_CAP', 'ADJCAP', 'TOTAL_CAP'];



	const handleExpandClick = () => {
		const newOpenState = !open;
		setOpen(newOpenState);
		if (onToggleExpand) {
			onToggleExpand(group.groupName, newOpenState); // Pass the group name and new open state  
		}
	};

	// // Function to calculate the sum of a property for all members  
	// function sumByProperty(arr, propName) {  
	//     return arr.reduce((total, obj) => {  
	//         const value = parseFloat(obj[propName]);  
	//         return total + (isNaN(value) ? 0 : value);  
	//     }, 0);  
	// }  



	const handleSuffixClick = (memberData) => (event) => {
		event.preventDefault();
		const providerDetailTabIndex = 1;

		// Summable properties that need to be aggregated  
		const sumProperties = ['CURRENT_MEMBER_COUNT', 'RETRO_MEMBER_COUNT', 'TOTAL_MEMBER_COUNT', 'CURRENT_STANDARD_SERVICE_CAP', 'RETRO_STANDARD_SERVICE_CAP', 'ADJCAP', 'TOTAL_CAP'];

		// Function to calculate the sum of a property for all members  
		function sumByProperty(arr, propName) {
			return arr.reduce((total, obj) => {
				const value = parseFloat(obj[propName]);
				return total + (isNaN(value) ? 0 : value);
			}, 0);
		}

		// Create an object with the sums of all properties  
		const sumOfProperties = sumProperties.reduce((acc, propName) => {
			acc[propName] = sumByProperty(group.members, propName);
			console.log("ARRAY TO DEBUG ADJ CAP IS", acc);
			return acc;
		}, {});

		// Create a new object that combines the sumOfProperties with the other properties of memberData  
		const combinedData = {
			...memberData, // Take all properties from the original memberData  
			...sumOfProperties, // Override with summed properties  
			CAP_PRD_YR_MO: "" // Set CAP_PRD_YR_MO to an empty string  
		};

		console.log("member data being routed to other components", combinedData);
		handleTabChange(combinedData, providerDetailTabIndex);
	};

	const handleChildSuffixClick = (memberData) => (event) => {
		event.preventDefault();
		const providerDetailTabIndex = 1;
		handleTabChange(memberData, providerDetailTabIndex);
	};



	const columnWidths = {
		showHistory: '7.7%',
		suffix: '5.4%',
		memberName: '15.7%',
		processMonth: '10.3%',
		capPeriod: '7.8%',
		contractOwnerMPIN: '15.3%',
		contract: '6.52%',
		contractLine: '10.6%',
		argt: '5.2%',
		capContractType: '11.5%',
		totalCap: '9.2%',
		// Add more widths for other columns if needed...  
	};

	function sumByProperty(arr, propName) {

		if (!Array.isArray(arr)) {
			console.error('sumByProperty: The first argument is not an array', arr);
			return 0; // Return 0 or some other default value  
		}

		return arr.reduce((total, obj) => {
			const value = parseFloat(obj[propName]);
			return total + (isNaN(value) ? 0 : value);
		}, 0);
	}



	// This example assumes that each JSON object is a separate row and does not have a history array.  
	return (
		<React.Fragment>
			{/** first row (suffix row) */}
			<TableRow id="member-table-main-row">
				<TableCell name='show-history-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} >
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={handleExpandClick}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell name='process-month-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{row.PAY_TO_PROV_ID}</TableCell>
				<TableCell name='suffix-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
					<TabContext value={currentTab}>
						<Link
							href="#"
							onClick={handleSuffixClick(row)}

						>
							{row.CONTR_ID}
						</Link>
					</TabContext>
				</TableCell>
				<TableCell name='process-month-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='cap-period-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{row.CONTR_LN_ID}</TableCell>
				<TableCell name='mpin-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{row.PROC_YR_MO}</TableCell>
				<TableCell name='contract-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='contract-line-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{ }</TableCell>
				<TableCell name='argt-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{row.PROV_ARNG_CD}</TableCell>
				<TableCell name='cap-contract-type-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{row.CONTR_TYP_CD}</TableCell>
				<TableCell name='cap-contract-type-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{parseFloat(sumByProperty(group.members, 'CURRENT_MEMBER_COUNT')).toLocaleString()}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{parseFloat(sumByProperty(group.members, 'RETRO_MEMBER_COUNT')).toLocaleString()}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{parseFloat(sumByProperty(group.members, 'TOTAL_MEMBER_COUNT')).toLocaleString()}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(sumByProperty(group.members, 'CURRENT_STANDARD_SERVICE_CAP'))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(sumByProperty(group.members, 'RETRO_STANDARD_SERVICE_CAP'))}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(`${sumByProperty(group.members, 'ADJCAP')}`)}</TableCell>
				<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }} sx={{ width: columnWidths.totalCap, pl: 2 }}>
					{formatDollars(sumByProperty(group.members, 'TOTAL_CAP'))}</TableCell>
			</TableRow>
			{open && (
				<>
					{group.members && group.members.length > 0 ? (
						group.members.map((member, index) => (
							<TableRow key={index}>
								<TableCell name='show-history-column' style={{ textAlign: 'right', whiteSpace: 'nowrap', height: '2rem', }}></TableCell>
								<TableCell name='suffix-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}></TableCell>
								<TableCell name='member-name-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}><Link href="#" onClick={handleChildSuffixClick(member)}>
									{member.CONTR_ID}
								</Link></TableCell>
								<TableCell name='process-month-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{member.PAY_TO_PROV_ID}</TableCell>
								<TableCell name='cap-period-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{member.CONTR_LN_ID}
								</TableCell>
								<TableCell name='mpin-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{member.PROC_YR_MO}</TableCell>
								<TableCell name='contract-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{member.ASSOC_PROV_ID}</TableCell>
								<TableCell name='contract-line-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}><Link href="#" onClick={handleChildSuffixClick(member)}>{member.CAP_PRD_YR_MO}</Link></TableCell>
								<TableCell name='argt-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{member.PROV_ARNG_CD}</TableCell>
								<TableCell name='cap-contract-type-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{member.CONTR_TYP_CD}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{Number(member.CURRENT_MEMBER_COUNT).toLocaleString()}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{Number(member.RETRO_MEMBER_COUNT).toLocaleString()}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{Number(member.TOTAL_MEMBER_COUNT).toLocaleString()}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{((member.CURRENT_STANDARD_SERVICE_CAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{formatDollars((member.RETRO_STANDARD_SERVICE_CAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{formatDollars((member.ADJCAP))}</TableCell>
								<TableCell name='total-cap-column' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
									{formatDollars((member.TOTAL_CAP))}</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan='100%'>No additional members</TableCell>
						</TableRow>
					)}
				</>
			)
			}
		</React.Fragment>
	);
}

export function HistorySearchTable({ searchResults, handleTabChange }) {


	const StripedTableBody = styled(TableBody)(({ theme }) => ({
		'& tr:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		'& tr:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	}));

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const { sortConfig, processData, SortButton } = useSortConfig();

	const tableRows = searchResults || [];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleDownloadEC = () => {  
		// Define the headers based on the table columns you want to include  
		const headers = [  
			'LEGAL_ENTITY','CAP_PRODUCT_CATEGORY','DATA_SEGMENT','PROCESS MONTH','CAP PERIOD', 'CURRENT_RETRO_INDICATOR', 'PAYMENT_CONTRACT_NUMBER',
			'PAYMENT_MED_PRVDR_ID_NUMBER', 'PAYMENT_CONTRACT_TIN', 'PAYMENT_CONTRACT_NAME', 'CONTRACT_OWNER_NUMBER', 'CONTRACT_OWNER_MED_PRVDR_ID_NUMBER',
			'CONTRACT_OWNER_TIN','CONTRACT_OWNER_NAME','CONTRACT_NUMBER', 'CONTRACT_MED_PRVDR_ID_NUMBER', 'CONTRACT_TIN', 'CONTRACT_NAME', 'ASSOC_CONTRACT_NUMBER',	
			'ASSOC_MED_PRVDR_ID_NUMBER', 'ASSOC_CONTRACT_TIN', 'ASSOC_CONTRACT_NAME', 'PROVIDER_ARRANGEMENT', 'CONTRACT_ARRANGEMENT', 'RATE_TYPE', 'PME_VALUE', 
			'CMS_CONTRACT_ID', 'PLAN_BENEFIT_PACKAGE_ID','CMS_SEGMENT', 'EMPLOYER_GROUP_NUMBER',	'EMPLOYER_GROUP_NAME',	'MEDICAID_STATE_PROGRAM_REVENUE_TYPE', 
			'PRODUCT_CODE','IPA_MARKET_TYPE', 'IPA_MARKET_NUMBER','IPA_NUMBER', 'PCP_SOURCE_SYSTEM_DIVISION',	'PCP_SOURCE_SYSTEM_PANEL',	'PCP_SOURCE_SYSTEM_NETWORK', 
			'PCP_TIN',	'PCP_MED_PRVDR_ID_NUMBER',	'NPI',	'SRC_SYS_PCP_PRVDR_ID','PCP_NAME',	'PCP_COUNTY_NAME', 'CAPPED_COUNT',	'TRANSACTION_COUNT', 'CAPITATION_RATE',	
			'PERCENT_OF_PREMIUM_RATE',	'CAPITATION_AMOUNT', 'ADJUSTMENT_CODE', 'BENEFIT_CODE',	'ADJUSTMENT_CATEGORY',	'ADJUSTMENT_RATE_TYPE',	'ADJUSTMENT_RATE',	'ADJUSTMENT_COMMENT',	
			'TRANSACTION_CAUSE', 'TRANSACTION_TERM_ADD', 'TRANSACTION_TYPE', 'TOTAL_MA_PAYMENT_AMOUNT', 'MLP_NET_PREM_AMT',	'REV_CARVEOUT_GROSS_AMT', 'REBATE_FOR_PART_D_SUPPLEMENTAL_BENEFITS_PART_A_AMOUNT',	
			'REBATE_FOR_PART_D_SUPPLEMENTAL_BENEFITS_PART_B_AMOUNT', 'REBATE_FOR_PART_D_BASIC_PREMIUM_REDUCTION', 'PART_D_BUY_DOWN_AMOUNT',	'CMS_USER_FEE_AMOUNT',	'SEQUESTRATION_AMOUNT',	'PREMIUM_TAX_AMOUNT', 
			'OTHER_FEE_AMT', 'TOTAL_PAYMENT_ADJ', 'CAPITATION_PREMIUM_GROSS_CAP', 'CMS_RAF_NET_PREM_AMT', 'CMS_RAF_NET_CAP_AMT', 'CMS_RAF_ADJUSTMENT_REASON_CODE',
			'RSVD_FLD_1', 'RSVD_FLD_2'
			
		];  
	  
		// Convert rowData to CSV format  
		const csvContent = [  
			headers.join(','), // header row first  
			...tableRows.map(row => [  
			row.LEGAL_ENTITY, row.CAP_PRODUCT_CATEGORY, row.DATA_SEGMENT, row.PROC_YR_MO, row.CAP_PRD_YR_MO, row.CURRENT_RETRO_INDICATOR, row.PAYMENT_CONTRACT_NUMBER,
			row.PAYMENT_MED_PRVDR_ID_NUMBER, row.PAYMENT_CONTRACT_TIN, row.PAYMENT_CONTRACT_NAME, row.CONTRACT_OWNER_NUMBER, row.CONTRACT_OWNER_MED_PRVDR_ID_NUMBER,
			row.CONTRACT_OWNER_TIN, row.CONTRACT_OWNER_NAME, row.CONTRACT_NUMBER, row.CONTRACT_MED_PRVDR_ID_NUMBER, row.CONTRACT_TIN, row.CONTRACT_NAME, row.ASSOC_CONTRACT_NUMBER,	
			row.ASSOC_MED_PRVDR_ID_NUMBER, row.ASSOC_CONTRACT_TIN, row.ASSOC_CONTRACT_NAME, row.PROV_ARNG_CD, row.CONTRACT_ARRANGEMENT, row.RATE_TYPE, row.PME_VALUE, 
			row.CMS_CONTRACT_ID, row.PLAN_BENEFIT_PACKAGE_ID, row.CMS_SEGMENT, row.EMPLOYER_GROUP_NUMBER, row.EMPLOYER_GROUP_NAME, row.MEDICAID_STATE_PROGRAM_REVENUE_TYPE, 
			row.PRODUCT_CODE, row.IPA_MARKET_TYPE, row.IPA_MARKET_NUMBER, row.IPA_NUMBER, row.PCP_SOURCE_SYSTEM_DIVISION, row.PCP_SOURCE_SYSTEM_PANEL, row.PCP_SOURCE_SYSTEM_NETWORK, 
			row.PCP_TIN, row.PCP_MED_PRVDR_ID_NUMBER, row.NPI, row.SRC_SYS_PCP_PRVDR_ID, row.PCP_NAME, row.PCP_COUNTY_NAME, row.CAPPED_COUNT, row.TRANSACTION_COUNT, row.CAPITATION_RATE,	
			row.PERCENT_OF_PREMIUM_RATE, row.CAPITATION_AMOUNT, row.ADJUSTMENT_CODE, row.BENEFIT_CODE, row.ADJUSTMENT_CATEGORY, row.ADJUSTMENT_RATE_TYPE, parseFloat(row.ADJCAP).toFixed(2), row.ADJUSTMENT_COMMENT,
	        row.TRANSACTION_CAUSE, row.TRANSACTION_TERM_ADD, row.TRANSACTION_TYPE, row.TOTAL_MA_PAYMENT_AMOUNT, row.MLP_NET_PREM_AMT, row.REV_CARVEOUT_GROSS_AMT, row.REBATE_FOR_PART_D_SUPPLEMENTAL_BENEFITS_PART_A_AMOUNT, 
			row.REBATE_FOR_PART_D_SUPPLEMENTAL_BENEFITS_PART_B_AMOUNT, row.REBATE_FOR_PART_D_BASIC_PREMIUM_REDUCTION, row.PART_D_BUY_DOWN_AMOUNT, row.CMS_USER_FEE_AMOUNT, row.SEQUESTRATION_AMOUNT, row.PREMIUM_TAX_AMOUNT, 
			row.OTHER_FEE_AMT, row.TOTAL_PAYMENT_ADJ, row.CMSMMR_PREMIUM_AMOUNT, row.CMS_RAF_NET_PREM_AMT, row.CMS_RAF_NET_CAP_AMT, row.CMS_RAF_ADJUSTMENT_REASON_CODE,
			row.RSVD_FLD_1, row.RSVD_FLD_2

			].join(',')) // map each row of data into a CSV string  
		].join('\n');  
	  
		// Create a Blob from the CSV Content  
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });  
	  
		// Create a link and set the URL using createObjectURL  
		const url = URL.createObjectURL(blob);  
		const link = document.createElement('a');  
		link.href = url;  
		link.download = 'EC7815.csv'; // Name the file here  
	  
		// Append to the document  
		document.body.appendChild(link);  
	  
		// Trigger 'click' to download  
		link.click();  
	  
		// Clean up and remove the link  
		document.body.removeChild(link);  
		URL.revokeObjectURL(url);  
	};  

		const handleDownload = () => {  
			// Define the headers based on the table columns you want to include  
			const headers = [  
				'Payment MPIN', 'Contract#', 'Contract Owner MPIN', 'Contract Line#',  
				'Process Month', 'Assoc Group', 'Cap Period',  
				'Arrangement Code', 'Current Member Count', 
				'Retro Member Count', 'Total Member Count', 'Current Standard Service Cap',  
				'Retro Standard Service Cap', 'Adjustment Cap', 'Total Cap'
			];  
		  
			// Convert rowData to CSV format  
			const csvContent = [  
				headers.join(','), // header row first  
				...tableRows.map(row => [  
					row.PAY_TO_PROV_ID, row.CONTR_ID, row.OWNR_PROV_ID, row.CONTR_LN_ID,
					row.PROC_YR_MO, row.ASSOC_PROV_ID, row.CAP_PRD_YR_MO,  
					row.PROV_ARNG_CD, row.CURRENT_MEMBER_COUNT,  
					row.RETRO_MEMBER_COUNT, row.TOTAL_MEMBER_COUNT,    parseFloat(row.CURRENT_STANDARD_SERVICE_CAP).toFixed(2),  
					parseFloat(row.RETRO_STANDARD_SERVICE_CAP).toFixed(2),parseFloat(row.ADJCAP).toFixed(2), parseFloat(row.TOTAL_CAP).toFixed(2) 
				].join(',')) // map each row of data into a CSV string  
			].join('\n');  
		  
			// Create a Blob from the CSV Content  
			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });  
		  
			// Create a link and set the URL using createObjectURL  
			const url = URL.createObjectURL(blob);  
			const link = document.createElement('a');  
			link.href = url;  
			link.download = 'History_Download.csv'; // Name the file here  
		  
			// Append to the document  
			document.body.appendChild(link);  
		  
			// Trigger 'click' to download  
			link.click();  
		  
			// Clean up and remove the link  
			document.body.removeChild(link);  
			URL.revokeObjectURL(url);  
		};  
		


	return (

		<Paper sx={{ width: '74%', overflow: 'hidden' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleDownloadEC}
                    id='download-button'
                >
                    EC7815
                </Button>
                <br/>
                <br/>
                <Button
                    onClick={handleDownload}
                    id='download-button'
                >
                    Download
                </Button>
            </div>

			{/* <div style={{ overflow: 'auto', maxHeight: '100vh' }}>   */}
			<TableContainer component={Paper} style={{ height: '68vh', width: '97%', overflow: 'auto' }}>
				<Table id="member-table" aria-label="collapsible table" stickyHeader>
					<TableHead id="member-table-header" style={{ backgroundColor: '#b0c0da', lineHeight: '0.4' }} >
						<TableRow id="member-table-row" style={{ backgroundColor: '#b0c0da', lineHeight: '1.0' }}>
							{/* <TableCell style={{ backgroundColor: '#b0c0da' }} /> */}
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>ShowAll</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>
								<SortButton label="Payment MPIN" sortKey="PAY_TO_PROV_ID" start='ascending'/>
							</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>
								<SortButton label="Contract#" sortKey="CONTR_ID" />
							</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Contract Owner<br /> MPIN</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Contract Line#</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>
								<SortButton label="Process Month" sortKey="PROC_YR_MO" />
							</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Assoc Group</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Cap Period</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Argt</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Cap Contract <br /> Type</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Current<br /> Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Retro <br />Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>Total <br /> Member Count</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>
								Current Standard<br />Service Cap
							</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center', whiteSpace: 'nowrap' }}>
								Retro Standard<br />Service Cap
							</TableCell>


							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Adj Cap</TableCell>
							<TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'center' }}>Total Cap</TableCell>
						</TableRow>
					</TableHead>
					<StripedTableBody>
						{/* {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={tableRows.CAP_PRD_YR_MO} row={row} handleTabChange={handleTabChange}  />
          ))} */}
						{processData(tableRows, sortConfig).map((group, index) => (
							<Row key={index} group={group} handleTabChange={handleTabChange} />
						))}

					</StripedTableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={processData(tableRows).length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</Paper>

	);
}
