import React from 'react';
import { Typography } from '@mui/material';

const ProviderHelp = () => {
    return (
        <div>
            <Typography id="help-modal-title" variant="h6" component="h1" sx={{ fontSize: '2rem' }}>
                Provider Help
            </Typography>
            <Typography id="help-modal-title" variant="h6" component="h2">
                Roles
            </Typography>
            <Typography variant="body1" paragraph>
                Each ECap user is assigned a Role; each Role is granted access to specific ECap features.
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to change information in the Roles screens. Some of the more senior Cap Ops staff has the authority to view the roles information.
            </Typography>
            <Typography variant="body1" paragraph>
                Available roles are:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Administrator</li>
                <li>Non ECap</li>
                <li>ECap Associate</li>
                <li>ECap Analyst</li>
                <li>ECap Lead</li>
                <li>Supervisor</li>
                <li>Manager</li>
                <li>Director</li>
                <li>Senior Management</li>
            </Typography>
            <Typography variant="body1" paragraph>
                The access rights are:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>View: View screens. User cannot perform any other activity.</li>
                <li>Add: Add transactions and View screens.</li>
                <li>Update: Change existing data and View screens.</li>
                <li>Delete: Delete transactions and View screens.</li>
            </Typography>
            <Typography variant="body1" paragraph>
                Access rights are granted at the level of the seven primary screen choices and the submenus within them. View, add, update and delete authority can be assigned, by role, to each of the following screens or groups or screens:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>Admin</li>
                <li>Roles</li>
                <li>Codes</li>
                <li>Group contracts</li>
                <li>Exclude contracts</li>
                <li>Maintenance</li>
                <li>Cap/payment adjustments</li>
                <li>Force cap parameter</li>
                <li>Retro period control</li>
                <li>Premium factors</li>
                <li>Benefit factors</li>
                <li>Member</li>
                <li>History</li>
                <li>Provider</li>
                <li>Contract</li>
                <li>Demographics</li>
                <li>Provider history</li>
                <li>Errors</li>
                <li>Job scheduling</li>
                <li>Reports</li>
                <li>Back to Menu</li>
                <li>Codes</li>
            </Typography>
            <Typography variant="body1" paragraph>
                ECap includes numerous codes for information such as adjustments, genders, calculation methods, and the like. The Codes screens provide access to this information.
            </Typography>
            <Typography variant="body1" paragraph>
                Only the ECap Administrator is able to add and change information in the Codes screens. Some of the more senior Cap Ops staff has the authority to view the codes information.
            </Typography>
            <Typography variant="body1" paragraph>
                Each type of information is identified by a Code Type (e.g., STATECODE for state codes, ADJCD for Adjustment Codes). Within each Code Type, the Code, Short Description and Long Description are available.
            </Typography>
            <Typography variant="body1" paragraph>
                Refer to &lt;Screen Notations&gt; for a description of the symbols on the screens.
            </Typography>
        </div>
    );
};

export default ProviderHelp;