/**
 * converts yyyy-mm-dd into mm/dd/yyyy
 * @param {string} yyyymmdd 
 * @returns
 */
export function yyyymmddToMmddyyyy(yyyymmdd) {
    try {
        var temp = yyyymmdd.split('-');
        if (temp.length === 3) {
            return temp[1] + '/' + temp[2] + '/' + temp[0];
        } else {
            return yyyymmdd
        }
    } catch {
        return yyyymmdd
    }
}

/**
 * converts yyyy-mm-dd into dd-mm-yyyy with mm in output as string
 * @param {string} yyyymmdd 
 * @returns
 */
export function yyyymmddToDdmmyyyy(yyyymmdd) {
    try {
        var temp = yyyymmdd.split('-');
        if (temp.length === 3) {
            switch (temp[1]) {
                case ('01'):
                    return temp[2] + '-Jan-' + temp[0];
                case ('02'):
                    return temp[2] + '-Feb-' + temp[0];
                case ('03'):
                    return temp[2] + '-Mar-' + temp[0];
                case ('04'):
                    return temp[2] + '-Apr-' + temp[0];
                case ('05'):
                    return temp[2] + '-May-' + temp[0];
                case ('06'):
                    return temp[2] + '-Jun-' + temp[0];
                case ('07'):
                    return temp[2] + '-Jul-' + temp[0];
                case ('08'):
                    return temp[2] + '-Aug-' + temp[0];
                case ('09'):
                    return temp[2] + '-Sep-' + temp[0];
                case ('10'):
                    return temp[2] + '-Oct-' + temp[0];
                case ('11'):
                    return temp[2] + '-Nov-' + temp[0];
                case ('12'):
                    return temp[2] + '-Dec-' + temp[0];
                default:
                    return ''
            }
        } else {
            return yyyymmdd
        }
    } catch {
        return yyyymmdd
    }
}

/**
 * Converts dttm in form string to date time format
 * @param {String} dttm 
 * @returns 
 */
export function dttmToTimestamp(dttm) {
    var temp = dttm.slice(0, -1).split('T');
    return yyyymmddToDdmmyyyy(temp[0]) + ' ' + temp[1];
}

/**
 * Converts a date string into yearmo format
 * @param {String} dateString 
 * @returns 
 */
export function formatYearMonth(dateString) {
    if (dateString === null || dateString === '' || dateString === undefined) {
        return ''
    }
    const date = new Date(dateString);
    const year = date.getFullYear(); // e.g., 2023  
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // e.g., "07" (getMonth() is zero-based)  
    return `${year}${month}`; // e.g., "202307"  
};

/**
 * Converts a date string into mm/dd/yyyy format
 * @param {String} dateString 
 * @returns 
 */
export function formatMmddyyyy(dateString) {
    if (dateString === null || dateString === '' || dateString === undefined) {
        return ''
    }
    const date = new Date(dateString);
    const year = date.getFullYear(); // e.g., 2023  
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // e.g., "07" (getMonth() is zero-based)  
    const day = ('0' + (date.getDate())).slice(-2);

    return `${month}/${day}/${year}`;
};

/**
 * rounds a string input to x decimals
 * @param {String} str 
 * @param {int} x 
 * @returns
 */
export function stringDecimals(str, x) {
    try {
        var temp = str.split('.');
        temp[temp.length - 1] = temp[temp.length - 1].slice(0, x);
        return temp.join('.');
    } catch {
        return str
    }
}

/**
 * converts a string into dollar format
 * @param {String} str 
 * @returns 
 */
export function formatDollars(str) {
    // no value or not a number  
    if (!str || isNaN(parseFloat(str))) {
        return '$0.00';
    }

    // Convert the string to a number and format it with two decimal places  
    var isNegative = false;
    if (str[0] == '-') {
        isNegative = true;
        str = str.substring(1);
    }
    const numberValue = parseFloat(str);
    str = numberValue.toFixed(2); // Ensures two decimal places  

    // Add commas and dollar sign  
    var temp = str.split('.');
    var sinceLastComma = 0;
    var converted = '';
    for (let i = temp[0].length - 1; i >= 0; i--) {
        if (sinceLastComma === 3) {
            sinceLastComma = 0;
            converted = ',' + converted;
        }
        converted = temp[0][i] + converted;
        sinceLastComma++;
    }

    // Append the decimal part (already guaranteed to be two digits from toFixed)  
    converted += '.' + temp[1];

    if (isNegative) {
        converted = '-' + converted;
    }

    return '$' + converted;
}

/**
 * simple check for whether an input is none. returns '' if the input is none.
 * @param {String} str 
 * @returns 
 */
export function checkNone(str) {
    if (!str || str === undefined || str === null || str === '' || str.toLowerCase() === 'none') {
        return '';
    }
    return str;
}


export function splitSourceSystemMemberKey(ssmk) {
    var splitSsmk;
    if (ssmk !== undefined && ssmk !== null && ssmk !== '') {
        splitSsmk = ssmk.split('-');
    }
    console.log(splitSsmk);
    return splitSsmk;
}
