import axios from "axios";
import { getToken } from "../../../TokenAPI.js";
const FORCE_ADD_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/force_cap/save`;

export const ForceAddApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(FORCE_ADD_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};
