import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import AdminHelp from './AdminHelp.js';
import MaintenanceHelp from './MaintenanceHelp.js';
import ErrorsHelp from './ErrorsHelp.js';
import ReportsHelp from './ReportsHelp.js';
import ProviderHelp from './ProviderHelp.js';
import MemberHelp from './MemberHelp.js';

const HelpButton = ({ index }) => {
    const [open, setOpen] = useState(false);
    const modalRef = useRef(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);   
    const helpBodyArray = [AdminHelp, MaintenanceHelp, MemberHelp, ProviderHelp, ReportsHelp, ErrorsHelp];
    const HelpComponent = helpBodyArray[index];
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (open && modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <div>
            <Button 
                onClick={handleOpen} 
                style={{ textTransform: 'none' }}
            >
                Help
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="help-modal-title"
                aria-describedby="help-modal-description"
            >
                <Box
                    ref={modalRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography id="help-modal-title" variant="h6" component="h2">
                    </Typography>
                    <HelpComponent />
                </Box>
            </Modal>
        </div>
    );
};

export default HelpButton;