import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    Alert,
    CircularProgress
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from "@mui/icons-material/Cancel";
import Papa from 'papaparse';
import { adjustmentsUploadApi } from './adjustmentsUploadAPI';
import { useSelector } from "react-redux";


export const Upload = ({ switchTab }) => {

    const [uiMessages, setUiMessages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [results, setResults] = useState([]);
    const email = useSelector((store) => store.user.userInfo.email);
    const [fileData, setFileData] = useState(null);

    const handleUpload = () => {
        const file = document.getElementById('fileInput').files[0];
        if (!file) {
            setUiMessages([['error', 'Please select a file to upload']]);
            return;
        }
        const reader = new FileReader();

        reader.onload = (event) => {
            Papa.parse(event.target.result, {
                complete: function (results) {
                    const data = results.data.filter(row => row.some(cell => cell !== ''));
                    setFileData(data);
                }
            });
        };

        reader.readAsText(file);
    }

    useEffect(() => {
        setUiMessages([]);
        if (!fileData) {
            return;
        }
        if (fileData.length < 2) {
            return;
        }
        if (!(
            fileData[0][0] == "INVC_ADJ_TYP_CD" &&
            fileData[0][1] == "INVC_ADJ_CATGY_CD" &&
            fileData[0][2] == "DATA_SEG_ID" &&
            fileData[0][3] == "CONTR_ID" &&
            fileData[0][4] == "CONTR_LN_ID" &&
            fileData[0][5] == "PROC_YR_MO" &&
            fileData[0][6] == "PROV_ARNG_CD" &&
            fileData[0][7] == "CAP_PRD_YR_MO" &&
            fileData[0][8] == "CURR_RETRO_CD" &&
            fileData[0][9] == "INVC_ADJ_AMT" &&
            fileData[0][10] == "INVC_DESC" &&
            fileData[0][11] == "HMO_ADC" &&
            fileData[0][12] == "PRODUCT_CODE" &&
            fileData[0][13] == "PRODUCT_CLASS_ID" &&
            fileData[0][14] == "FINANCIAL_MARKET_SEGMENT_CODE" &&
            fileData[0][15] == "SPECIALTY_CODE" &&
            fileData[0][16] == "FUNDING_ARRANGEMENT" &&
            fileData[0][17] == "PROV_ID" &&
            fileData[0][18] == "VEND_TAX_ID" &&
            fileData[0][19] == "CONTRACT_OWNER_MPIN" &&
            fileData[0][20] == "ASSOC_GROUP" &&
            fileData[0][21] == "MBR_SRC_SYS_CD" &&
            fileData[0][22] == "CUST_ID" &&
            fileData[0][23] == "MBR_ID" &&
            fileData[0][24] == "MBR_ID_SUFX" &&
            fileData[0][25] == "ADJ_CATGY_CD" &&
            fileData[0][26] == "INVC_ADJ_RSN_CD" &&
            fileData[0][27] == "PAY_NOW_IND" &&
            fileData[0][28] == "AUTO_RVRS_IND" &&
            fileData[0][29] == "BCKUP_FL_EXT" &&
            fileData[0][30] == "INVC_ID" &&
            fileData[0][31] == "ADDR_SEQ_NBR" &&
            fileData[0][32] == "PREPARED_BY" &&
            fileData[0][33] == "PREPARED_DATE" &&
            fileData[0][34] == "REVIEWED_BY" &&
            fileData[0][35] == "REVIEWED_DATE" &&
            fileData[0][36] == "APPROVED_BY_1" &&
            fileData[0][37] == "APPROVED_DATE_1" &&
            fileData[0][38] == "APPROVED_BY_2" &&
            fileData[0][39] == "APPROVED_DATE_2" &&
            fileData[0][40] == "APPROVED_BY_3" &&
            fileData[0][41] == "APPROVED_DATE_3" &&
            fileData[0][42] == "CONTR_ARNG_CD" &&
            fileData[0][43] == "POL_ISS_ST_CD" &&
            fileData[0][44] == "COVERAGE_SOURCE_SYSTEM" &&
            fileData[0][45] == "EMPLOYER_GROUP" &&
            fileData[0][46] == "PROVIDER_NETWORK"
        
        )) {
            // wrong header msg
            setUiMessages([['error', 'Invalid Header Record']]);
            return;
        }
        setUploading(true);
        var requestBody = []
        // valid format
        for (let i = 1; i < fileData.length; i++) {
            // depending on validations we can put some here
            let tempRow = {
                INVC_ADJ_TYP_CD: fileData[i][0],
                INVC_ADJ_CATGY_CD: fileData[i][1],
                DATA_SEG_ID: fileData[i][2],
                CONTR_ID: fileData[i][3],
                CONTR_LN_ID: fileData[i][4],
                PROC_YR_MO: fileData[i][5],
                PROV_ARNG_CD: fileData[i][6],
                CAP_PRD_YR_MO: fileData[i][7],
                CURR_RETRO_CD: fileData[i][8],
                INVC_ADJ_AMT: fileData[i][9],
                INVC_DESC: fileData[i][10],
                HMO_ADC: fileData[i][11],
                PRODUCT_CODE: fileData[i][12], // backend is hardcoded to system...
                PRODUCT_CLASS_ID: fileData[i][13],
                FINANCIAL_MARKET_SEGMENT_CODE: fileData[i][14],
                SPECIALTY_CODE: fileData[i][15],
                FUNDING_ARRANGEMENT: fileData[i][16], 
                PROV_ID: fileData[i][17], 
                VEND_TAX_ID: fileData[i][18],
                CONTRACT_OWNER_MPIN: fileData[i][19],
                ASSOC_GROUP: fileData[i][20], 
                MBR_SRC_SYS_CD: fileData[i][21],
                CUST_ID: fileData[i][22],
                MBR_ID: fileData[i][23],
                MBR_ID_SUFX: fileData[i][24],
                ADJ_CATGY_CD: fileData[i][25],
                INVC_ADJ_RSN_CD: fileData[i][26],
                PAY_NOW_IND: fileData[i][27],
                AUTO_RVRS_IND: fileData[i][28],
                BCKUP_FL_EXT: fileData[i][29],
                INVC_ID: fileData[i][30],
                ADDR_SEQ_NBR: fileData[i][31],
                PREPARED_BY: fileData[i][32],
                PREPARED_DATE: fileData[i][33],
                REVIEWED_BY: fileData[i][34],
                REVIEWED_DATE: fileData[i][35],
                APPROVED_BY_1: fileData[i][36],
                APPROVED_DATE_1: fileData[i][37],
                APPROVED_BY_2: fileData[i][38],
                APPROVED_DATE_2: fileData[i][39],
                APPROVED_BY_3: fileData[i][40],
                APPROVED_DATE_3: fileData[i][41],
                CONTR_ARNG_CD: fileData[i][42],
                POL_ISS_ST_CD: fileData[i][43],
                COVERAGE_SOURCE_SYSTEM: fileData[i][44],
                EMPLOYER_GROUP: fileData[i][45],
                PROVIDER_NETWORK: fileData[i][46],
                UPDT_USER_ID: localStorage.getItem('sub'),
                EMAIL_ID: email
       };

requestBody.push(tempRow);
}
        // api returns a list, e.g. ['... succeeded ...', '... failed... ', '... failed ...', '... succeeded ...', ...]
        adjustmentsUploadApi(requestBody).then(response => {
            let tempSuccess = 0;
            let tempFailed = 0;
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].status === 'success') {
                    tempSuccess++;
                } else {
                    tempFailed++;
                }
            }
            setResults([tempSuccess, tempFailed]);
            setUploading(false);
            setCompleted(true);
        }).catch(error => {
            console.log(error);
            setResults([0, requestBody.length]);
            setUploading(false);
            setCompleted(true);
        });
    }, [fileData])

    useEffect(() => {
        console.log(results);
    }, [results])

    return (
        <>
            {completed ?
                <Box sx={{ color: 'green' }}>
                    <Typography variant='h6'>
                        Adjustments upload completed. See summary below. Detailed report is sent to {email}.
                    </Typography>
                    <Typography variant='h6'>Success: {results[0]}</Typography>
                    <Typography variant='h6'>Failed: {results[1]}</Typography>
                </Box>
                :
                <Box sx={{ mt: 1 }}>
                    <Box><Typography variant='body2'>Please select a CSV (Comma Separated Values) file</Typography></Box>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ alignContent: 'center', width: 200 }}>
                            <Typography variant='h6' sx={{ display: 'flex' }}><p style={{ color: 'red' }}>*</p>Select File to Upload</Typography>
                        </Box>
                        <input id='fileInput' type="file" accept=".csv" />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant='contained'
                            startIcon={<CloudUploadIcon />}
                            sx={{ mr: 1 }}
                            onClick={handleUpload}
                            disabled={uploading}
                        >
                            Upload
                            {uploading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            startIcon={<CancelIcon />}
                            onClick={() => switchTab(0)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            }
            <Box sx={{ width: 500, mt: 2 }}>
                {uiMessages.map((message, index) => {
                    if (message[0] === 'error') {
                        return (
                            <Alert
                                severity='error'
                                size='small'
                                key={'alert_' + index}
                                sx={{ mb: 0.5, opacity: '.9', '&:hover': { backgroundColor: '#ffb6a7', cursor: 'pointer' } }}
                                onClick={() => setUiMessages(uiMessages.filter((_, i) => i !== index))}
                            >
                                {message[1]}
                            </Alert>)
                    } else if (message[0] === 'warning') {
                        return (
                            <Alert
                                severity='warning'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    } else if (message[0] === 'success') {
                        return (
                            <Alert
                                severity='success'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    }
                })}
            </Box>
        </>
    )
};
export default Upload;
