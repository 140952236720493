import axios from "axios";
import { getToken } from "../../../TokenAPI";
const SYS_DEFAULT_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/retro/sys_default`;
const RETRO_ADD_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/retro/add`;

export const retroAddSysDefaultApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(SYS_DEFAULT_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};

export const retroAddApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(RETRO_ADD_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};
