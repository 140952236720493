import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import { MonthlyGroupApi } from './MonthlyProcessedApi';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { dttmToTimestamp } from '../../helperFunctions';
import { getMonthlyGroup, setMonthlyGroup } from '../errorPersistence';
import useSortConfig from '../../sort_config';

export const MonthlyGroup = ({
    setRenderChoice,
    inputGroupData,
    adjustListData
}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { sortConfig, SortButton } = useSortConfig();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

        const handleDownload = () => {  
		// Define the headers based on the table columns you want to include  
        const headers = [  
			'Error Value', 'Error Field', 'Job ID', 'Cycle Reference',  
			'Process Month', 'Cap Period', 'Count',  
			'Time Stamp'
		];  
        const csvContent = [
            headers.join(','), // header row first
            ...sortedData.map(row => [
                row.ERR_DATA_VAL, row.ERR_FLD_NM, row.JOB_ID, row.CYC_RUN_SEQ_NBR,
                row.PROC_YR_MO, row.CAP_PRD_YR_MO, row.CNT, dttmToTimestamp(row.CREAT_DTTM)
            ].join(',')) // map each row of data into a CSV string
        ].join('\n'); // join rows with newline character
        
        // Create a Blob from the CSV Content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        // Create a link and set the URL using createObjectURL
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'MonthlyGroup.csv'; // Name the file here
        
        // Append to the document
        document.body.appendChild(link);
        
        // Trigger 'click' to download
        link.click();
        
        // Clean up and remove the link
        document.body.removeChild(link);
    };


    useEffect(() => {
        setErrorMessage('');
        var temp = getMonthlyGroup();
        if (temp) {
            setTableData(temp);
            setLoadingTable(false);
            setShowTable(true);
        } else {
            MonthlyGroupApi(inputGroupData).then(response => {
                setLoadingTable(false);
                if (response.data.length > 0) {
                    setShowTable(true);
                    setTableData(response.data);
                    setMonthlyGroup(response.data);
                    adjustListData('DATA_SEG_ID', inputGroupData['DATA_SEG_ID']);
                    adjustListData('JOB_TYP_CD', inputGroupData['JOB_TYP_CD']);
                    adjustListData('PROC_YR_MO', inputGroupData['PROC_YR_MO']);
                    adjustListData('CYC_RUN_SEQ_NBR', inputGroupData['CYC_RUN_SEQ_NBR']);
                    adjustListData('ERR_TYP_CD', inputGroupData['ERR_TYP_CD']);
                    adjustListData('ERR_SEV_CD', inputGroupData['ERR_SEV_CD']);
                    adjustListData('LNG_DESC', inputGroupData['LNG_DESC']);
                } else {
                    setErrorMessage('No data found');
                }
            }).catch(error => {
                console.log(error);
                setLoadingTable(false);
                setErrorMessage('No data found');
            })
        }
    }, [inputGroupData])

    function monthlyGroupClick(value, jobId, procYrMo, capProcYrMo, createDttm) {
        adjustListData('ERR_DATA_VAL', value)
        adjustListData('JOB_ID', jobId);
        adjustListData('PROC_YR_MO', procYrMo);
        adjustListData('CAP_PRD_YR_MO', capProcYrMo);
        adjustListData('CREAT_DTTM', createDttm);
        setRenderChoice(2);
    }

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    return (
        <div style={{ width: '98vw' }}>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputGroupData['DATA_SEG_ID'])} | Error Severity: {inputGroupData['ERR_SEV_CD']} | Error Category: {inputGroupData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputGroupData['ERR_TYP_CD']} | Error Message: {inputGroupData['LNG_DESC']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Monthly Processed Search Results</a> {'>'} Error Group by Value
            </Typography>

            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Value</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Field</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Job ID</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Cycle Reference</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                            <SortButton label="Process Month" sortKey="PROC_YR_MO" start='ascending'/>   
                                        </TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                            <SortButton label="Cap Period" sortKey="CAP_PRD_YR_MO" />
                                        </TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Count</TableCell>
                                        <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Time Stamp</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ textAlign: 'left' }}><a href='#' onClick={() => monthlyGroupClick(row.ERR_DATA_VAL, row.JOB_ID, row.PROC_YR_MO, row.CAP_PRD_YR_MO, row.CREAT_DTTM)}>{row.ERR_DATA_VAL}</a></TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.ERR_FLD_NM}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.JOB_ID}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CYC_RUN_SEQ_NBR}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.PROC_YR_MO}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CAP_PRD_YR_MO}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{row.CNT}</TableCell>
                                                    <TableCell style={{ textAlign: 'left' }}>{dttmToTimestamp(row.CREAT_DTTM)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%', mt: '1rem' }}>
                    <LinearProgress />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red', marginTop: '1rem' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};