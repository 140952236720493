import axios from "axios";
import { getToken } from "../../TokenAPI";
const RETRO_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_MAINTAINANCE_URL}/maintenance/retro/search`;

export const RetroSearchApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(RETRO_SEARCH_ENDPOINT, requestBody, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
};
