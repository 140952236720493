import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    Alert,
    CircularProgress
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from "@mui/icons-material/Cancel";
import Papa from 'papaparse';
import { parametersUploadApi } from './parametersUploadApi';
import { useSelector } from "react-redux";

export const ParametersUpload = ({ switchTabs }) => {

    const [uiMessages, setUiMessages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [results, setResults] = useState([]);
    const email = useSelector((store) => store.user.userInfo.email);
    const [fileData, setFileData] = useState(null);

    const handleUpload = () => {
        const file = document.getElementById('fileInput').files[0];
        if (!file) {
            setUiMessages([['error', 'Please select a file to upload']]);
            return;
        }
        const reader = new FileReader();

        reader.onload = (event) => {
            Papa.parse(event.target.result, {
                complete: function (results) {
                    const data = results.data.filter(row => row.some(cell => cell !== ''));
                    setFileData(data);
                }
            });
        };

        reader.readAsText(file);
    }

    useEffect(() => {
        setUiMessages([]);
        if (!fileData) {
            return;
        }
        if (fileData.length < 2) {
            return;
        }
        if (!(
            fileData[0][0] == "DATA_SEG_ID" &&
            fileData[0][1] == "FRC_CAP_TYP_CD" &&
            fileData[0][2] == "CONTR_ID" &&
            fileData[0][3] == "CONTR_LN_ID" &&
            fileData[0][4] == "MBR_SRC_SYS_CD" &&
            fileData[0][5] == "POL_ID" &&
            fileData[0][6] == "CAP_BEN_PLN_ID" &&
            fileData[0][7] == "CUST_ID" &&
            fileData[0][8] == "MBR_ID" &&
            fileData[0][9] == "MBR_ID_SUFX" &&
            fileData[0][10] == "CAP_PRD_BEGN_YR_MO" &&
            fileData[0][11] == "CAP_PRD_END_YR_MO" &&
            fileData[0][12] == "REPROC_CD" &&
            fileData[0][13] == "COV_SRC_SYS_CD" &&
            fileData[0][14] == "CONTR_CHG_TYP_CD" &&
            fileData[0][15] == "DEL_CD" 
            // fileData[0][16] == "CMS_CONTR_ID" &&
            // fileData[0][17] == "PLN_BEN_PCK_ID" &&
            // fileData[0][18] == "PBP_SEG_ID" &&
            // fileData[0][19] == "PCP_DIV_CD" &&
            // fileData[0][20] == "PCP_PNL_ID" &&
            // fileData[0][21] == "PCP_NTWK_ID"
        )) {
            // wrong header msg
            setUiMessages([['error', 'Invalid Header Record']]);
            return;
        }
        setUploading(true);
        var requestBody = []
        // valid format
        for (let i = 1; i < fileData.length; i++) {
            // depending on validations we can put some here
            let tempRow = {
                "DATA_SEG_ID": fileData[i][0],
                "FRC_CAP_TYP_CD": fileData[i][1],
                "CONTR_ID": fileData[i][2],
                "CONTR_LN_ID": fileData[i][3],
                "MBR_SRC_SYS_CD": fileData[i][4],
                "POL_ID": fileData[i][5],
                "CAP_BEN_PLN_ID": fileData[i][6],
                "CUST_ID": fileData[i][7],
                "MBR_ID": fileData[i][8],
                "MBR_ID_SUFX": fileData[i][9],
                "CAP_PRD_BEGN_YR_MO": fileData[i][10],
                "CAP_PRD_END_YR_MO": fileData[i][11],
                "REPROC_CD": fileData[i][12], // backend is hardcoded to system...
                "COV_SRC_SYS_CD": fileData[i][13],
                "CONTR_CHG_TYP_CD": fileData[i][14],
                "DEL_CD": fileData[i][15],
                // "CMS_CONTR_ID": fileData[i][16], // not used in backend...
                // "PLN_BEN_PCK_ID": fileData[i][17], // not used in backend...
                // "PBP_SEG_ID": fileData[i][18], // not used in backend...
                // "PCP_DIV_CD": fileData[i][19], // not used in backend...
                // "PCP_PNL_ID": fileData[i][20], // not used in backend...
                // "PCP_NTWK_ID": fileData[i][21], // not used in backend...
                "UPDT_USER_ID": localStorage.getItem('sub'),
                "EMAIL_ID": email
            }
            requestBody.push(tempRow);
        }
        // api returns a list, e.g. ['... succeeded ...', '... failed... ', '... failed ...', '... succeeded ...', ...]
        console.log(requestBody);
        parametersUploadApi(requestBody).then(response => {
            let tempSuccessful = 0;
            let tempFailed = 0;
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i] === "") {
                    tempSuccessful++;
                } else {
                    tempFailed++;
                }
            }
            setResults([tempSuccessful, tempFailed]);
            setUploading(false);
            setCompleted(true);
        }).catch(error => {
            console.log(error);
            setResults([0, requestBody.length]);
            setUploading(false);
            setCompleted(true);
        });
    }, [fileData])

    useEffect(() => {
        console.log(results);
    }, [results])

    return (
        <>
            {completed ?
                <Box sx={{ color: 'green' }}>
                    <Typography variant='h6'>
                        ForceCap upload completed. See summary below. Detailed report is sent to {email}.
                    </Typography>
                    <Typography variant='h6'>Success: {results[0]}</Typography>
                    <Typography variant='h6'>Failed: {results[1]}</Typography>
                </Box>
                :
                <Box sx={{ mt: 1 }}>
                    <Box><Typography variant='body2'>Please select a CSV (Comma Separated Values) file</Typography></Box>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ alignContent: 'center', width: 200 }}>
                            <Typography variant='h6' sx={{ display: 'flex' }}><p style={{ color: 'red' }}>*</p>Select File to Upload</Typography>
                        </Box>
                        <input id='fileInput' type="file" accept=".csv" />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant='contained'
                            startIcon={<CloudUploadIcon />}
                            sx={{ mr: 1 }}
                            onClick={handleUpload}
                            disabled={uploading}
                        >
                            Upload
                            {uploading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            startIcon={<CancelIcon />}
                            onClick={() => switchTabs(0)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            }
            <Box sx={{ width: 500, mt: 2 }}>
                {uiMessages.map((message, index) => {
                    if (message[0] === 'error') {
                        return (
                            <Alert
                                severity='error'
                                size='small'
                                key={'alert_' + index}
                                sx={{ mb: 0.5, opacity: '.9', '&:hover': { backgroundColor: '#ffb6a7', cursor: 'pointer' } }}
                                onClick={() => setUiMessages(uiMessages.filter((_, i) => i !== index))}
                            >
                                {message[1]}
                            </Alert>)
                    } else if (message[0] === 'warning') {
                        return (
                            <Alert
                                severity='warning'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    } else if (message[0] === 'success') {
                        return (
                            <Alert
                                severity='success'
                                size='small'
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    }
                })}
            </Box>
        </>
    )
};
export default ParametersUpload;
