import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button } from '@mui/material';
import useSortConfig from '../../sort_config.js';

export const AgingTable = ({
    setRenderChoice,
    inputTableData,
    adjustDetailData
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const { sortConfig, SortButton } = useSortConfig();


    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
        }
        setPage(0);
    }, [inputTableData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {
        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'AgingTable.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    function onCountClick(code, severity, message, count, prdAgeCd, over6) {
        adjustDetailData('ERR_TYP_CD', code);
        adjustDetailData('ERR_SEV_CD', severity);
        adjustDetailData('LNG_DESC', message);
        adjustDetailData('CNT', count);
        adjustDetailData('PRD_AGE_CD', prdAgeCd);
        adjustDetailData('over6', over6)
        setRenderChoice(1);
    }

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleDownload}
                    id='download-button'
                >
                    Download
                </Button>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ backgroundColor: '#b0c0da' }}>
                            <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                    <SortButton label="Error Code" sortKey="ERR_TYP_CD" start='ascending'/>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                    <SortButton label="Error Severity" sortKey="ERR_SEV_CD" />
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Message</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Current Count</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>1 Month Count</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>2 Month Count</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>3 Month Count</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>4 to 6 Month Count</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Over 6 Month Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.ERR_TYP_CD}>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_TYP_CD}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_SEV_CD}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.LNG_DESC}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['CURRENT'], row.PRD_AGE_CD, false)}>{row['CURRENT']}</a></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['1-MONTH'], row.PRD_AGE_CD, false)}>{row['1-MONTH']}</a></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['2-MONTH'], row.PRD_AGE_CD, false)}>{row['2-MONTH']}</a></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['3-MONTH'], row.PRD_AGE_CD, false)}>{row['3-MONTH']}</a></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['4-6-MONTH'], row.PRD_AGE_CD, false)}>{row['4-6-MONTH']}</a></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => onCountClick(row.ERR_TYP_CD, row.ERR_SEV_CD, row.LNG_DESC, row['OVER-6'], row.PRD_AGE_CD, true)}>{row['OVER-6']}</a></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        '.MuiTablePagination-selectLabel': {
                            paddingTop: '12px',
                        },
                        '.MuiTablePagination-displayedRows': {
                            paddingTop: '13px',
                        },
                    }}
                />
            </Paper>
        </div>
    );
};
export default AgingTable;