import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableFooter,
    TableRow,
    Paper,
    Checkbox,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
    MenuItem,
    Select
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDataSegments, getDataSegmentIntFromString } from "../../../Home/dataSegmentApi"
import { ParameterUpdateRequest, ParameterDeleteRequest } from './ParameterSearchRequest';
import { splitSourceSystemMemberKey } from '../../../helperFunctions';
import useSortConfig from '../../../sort_config.js';


export const ParametersSeachTable = ({ showTable, inputTableData, inputForceCapType, inputDataSegment, inputError }) => {
    const userId = localStorage.getItem("sub");
    const [dataSegment, setDataSegment] = useState('');
    const [dataSegments, setDataSegments] = useState(['']);
    const [forceCaptype, setForceCapType] = useState('PROVIDER');
    const [sourceSystem, setSourceSystem] = useState('DEFAULT');
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]); // Store selected row IDs
    const [editFormData, setEditFormData] = useState([]); // Store form data for editing
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar for warning message
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [errors, setErrors] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [sourceSystemSelections, setSourceSystemSelections] = useState({});
    const [noDataFound, setNoDataFound] = useState(false);
    const { sortConfig, getSortIcon, handleSort, SortButton } = useSortConfig();


    useEffect(() => {
        const initialSourceSystemSelections = tableData.reduce((acc, row) => {
            acc[row.FRC_CAP_ID] = "DEFAULT";
            return acc;
        }, {});
        setSourceSystemSelections(initialSourceSystemSelections);
    }, [tableData]);

    useEffect(() => {
        if (inputTableData !== undefined) {
            setTableData(inputTableData);
            setSelectedRows([]); // Reset selected rows  
            setSelectAllChecked(false); // Uncheck the 'select all' checkbox  
            setNoDataFound(false);
        }
        setPage(0); // Reset pagination to the first page  
    }, [inputTableData]);

    useEffect(() => {
        var inputDataSegments = getDataSegments();
        var dataIds = {}
        var dataList = []
        for (let index in inputDataSegments) {
            dataIds[inputDataSegments[index]['NAME']] = inputDataSegments[index]['DATA_SEG_ID'];
            dataList.push(inputDataSegments[index]['NAME']);
        }
        setDataSegments(dataList);
    }, [])

    const handleDataSegmentChange = (event) => {
        setDataSegment(event.target.value);
    }

    const handleForceCapTypeChange = (event) => {
        setForceCapType(event.target.value);
    }

    const handleSourceSystemChange = (event) => {
        setSourceSystem(event.target.value);
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle checkbox selection
    const handleCheckboxChange = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    // Handle Select All
    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        setSelectAllChecked(checked);
        if (checked) {
            const allRowIds = tableData.map((row) => row.FRC_CAP_ID);
            setSelectedRows(allRowIds);
        } else {
            setSelectedRows([]);
        }
    };

    // Handle Edit Click (opens dialog)
    const handleUpdateClick = () => {
        if (selectedRows.length === 0) {
            setSnackbarMessage('Please check at least one row.');
            setSnackbarOpen(true);
            return;
        }

        const selectedRowData = tableData.filter((row) => selectedRows.includes(row.FRC_CAP_ID));
        for (let i = 0; i < selectedRowData.length; i++) {
            if (selectedRowData[i].REPO_CD !== 'REPROCESS' && selectedRowData[i].REPO_CD !== 'FORCE') {
                setSnackbarMessage('Update is not permitted when the Reprocess Indicator is '.concat(selectedRowData[i].REPO_CD));
                setSnackbarOpen(true);
                return;
            }
            if (selectedRowData[i].PROC_YR_MONTH !== "" && selectedRowData[i].PROC_YR_MONTH !== 'None') {
                setSnackbarMessage('Not allow to update: Cap has already been processed for this effective period');
                setSnackbarOpen(true);
                return
            }
        }
        setEditFormData(selectedRowData);
        setDialogOpen(true);
    };

    // Handle Delete Click
    const handleDeleteClick = () => {
        const deleteSelectedRow = tableData.filter((row) => selectedRows.includes(row.FRC_CAP_ID));
        if (selectedRows.length === 0) {
            setSnackbarMessage('Please check at least one row.');
            setSnackbarOpen(true);
            return;
        }

        // Check if the selected row has already been processed, if so, not allow to delete
        for (let i = 0; i < deleteSelectedRow.length; i++) {
            const deleteObject = deleteSelectedRow[i];
            const processMonth = deleteObject.PROC_YR_MONTH;
            if (processMonth !== "" && processMonth !== 'None') {
                setSnackbarMessage('Not allow to delete: Cap has already been processed for this effective period');
                setSnackbarOpen(true);
                return
            }
        }

        // Add confirmation before deletion
        const confirmation = window.confirm('Are you sure you want to delete?');
        if (!confirmation) {
            return;
        }

        // const deleteSelectedRow = tableData.filter((row) => selectedRows.includes(row.FRC_CAP_ID));
        for (let i = 0; i < deleteSelectedRow.length; i++) {
            const deleteObject = deleteSelectedRow[i];

            const providerDeleteRequestBody = [{
                "FRC_CAP_ID": `${deleteObject.FRC_CAP_ID}`
            }]

            ParameterDeleteRequest(providerDeleteRequestBody).then(response => {
                if (response.status === 200) {
                    console.log('Deleted successfully', response.data)
                }
            }
            );
        }

        setDeleteSuccess(true);
        const updatedData = tableData.filter((row) => !selectedRows.includes(row.FRC_CAP_ID));
        setTableData(updatedData);
        // Clear the selection after deletion
        setSelectedRows([]);
        setSelectAllChecked(false);
    };

    // Validate update Function 
    const validate = () => {
        let isValid = true;
        let tempErrors = [];
        let rowErros = {
            contractNumber: '',
            contractLineNumber: '',
            beginCapPeriod: '',
            endCapPeriod: '',
            processMonth: '',
            reprocessIndicator: '',
            employerGroup: '',
            benefitPlanId: '',
            sourceSystem: ''
        };

        // for (var j = 0; j < editFormData.length; j++) {
        //     const editFormDataObj = editFormData[j];
        //     for (var k = 0; k < tableData.length; k++) {
        //         if (editFormDataObj.FRC_CAP_ID === tableData[k].FRC_CAP_ID) {
        //             if (tableData[k].REPO_CD !== 'REPROCESS') {
        //                 rowErros.reprocessIndicator = 'Update is not permitted when the Reprocess Indicator is '.concat(tableData[k].REPO_CD);
        //                 tempErrors.push(rowErros);
        //                 setErrors(tempErrors);
        //                 return false;
        //             }
        //             if (tableData[k].PROC_YR_MONTH !== "" && tableData[k].PROC_YR_MONTH !== 'None') {
        //                 rowErros.processMonth = 'Cap has already been processed for this effective period';
        //                 tempErrors.push(rowErros);
        //                 setErrors(tempErrors);
        //                 return false;
        //             }
        //             break;
        //         }
        //     }
        // }

        for (var i = 0; i < editFormData.length; i++) {

            // Provider validation
            if (inputForceCapType == 'PROVIDER') {
                // Contract Number validation
                if (!editFormData[i].CONTRACT_ID) {
                    rowErros.contractNumber = 'Contract Number is required';
                    isValid = false;
                } else if (isNaN(editFormData[i].CONTRACT_ID)) {
                    rowErros.contractNumber = 'Contract Number should be a number';
                    isValid = false;
                } else {
                    delete rowErros.contractNumber;
                }

                // Contract Line Number validation
                if (editFormData[i].CONTRACT_LN_ID === '' || editFormData[i].CONTRACT_LN_ID === null) {
                    rowErros.contractLineNumber = 'Contract Line Number is required';
                    isValid = false;
                } else if (isNaN(editFormData[i].CONTRACT_LN_ID)) {
                    rowErros.contractLineNumber = 'Contract Line Number should be a number';
                    isValid = false;
                } else {
                    delete rowErros.contractLineNumber;
                }
            }

            // Customer & Benefit Factor validation
            if (inputForceCapType == 'CUSTOMER' || inputForceCapType == 'BENEFIT FACTOR') {
                // Employer Group validation
                if (!editFormData[i].EMP_GROUP_NUMBER) {
                    rowErros.employerGroup = 'Employer Group is required';
                    isValid = false;
                } else {
                    delete rowErros.employerGroup;
                }

                // Benefit Plan ID validation
                if (!editFormData[i].BENEFIT_PLAN_ID) {
                    rowErros.benefitPlanId = 'Benefit Plan ID is required';
                    isValid = false;
                } else {
                    delete rowErros.benefitPlanId;
                }

                // Source System validation
                const selectedSourceSystem = sourceSystemSelections[editFormData[i].FRC_CAP_ID];
                if (!selectedSourceSystem || selectedSourceSystem === 'DEFAULT') {
                    rowErros.sourceSystem = 'Source System is required';
                    isValid = false;
                } else {
                    delete rowErros.sourceSystem;
                }

            }

            // Begain Cap Period validation (YYYYMM)
            if (editFormData[i].CAP_PRD_BEGIN === '' || editFormData[i].CAP_PRD_BEGIN === null) {
                rowErros.beginCapPeriod = 'Begin Cap Period is required';
                isValid = false;
            } else if (!/^\d{4}(0[1-9]|1[0-2])$/.test(editFormData[i].CAP_PRD_BEGIN)) {
                rowErros.beginCapPeriod = 'Begin Cap Period should be in YYYYMM format';
                isValid = false;
            } else {
                delete rowErros.beginCapPeriod;
            }

            // End Cap Period validate (YYYYMM)
            if (editFormData[i].CAP_PRD_END === '' || editFormData[i].CAP_PRD_END === null) {
                rowErros.endCapPeriod = 'End Cap Period is required';
                isValid = false;
            } else if (!/^\d{4}(0[1-9]|1[0-2])$/.test(editFormData[i].CAP_PRD_END)) {
                rowErros.endCapPeriod = 'End Cap Period should be in YYYYMM format';
                isValid = false;
            } else {
                delete rowErros.endCapPeriod;
            }

            // Reprocess Indicator Validation
            if (editFormData[i] === '' || editFormData[i].REPO_CD === null) {
                rowErros.reprocessIndicator = 'Reprocess Indicator is required';
                isValid = false;
            } else {
                delete rowErros.reprocessIndicator;
            }
            tempErrors.push({ ...rowErros });
        }
        setErrors(tempErrors);
        return isValid;
    }

    // Handle Dialog Save
    const handleDialogSave = () => {
        if (validate()) {
            const updatedData = tableData.map((row) =>
                editFormData.find((editRow) => editRow.FRC_CAP_ID === row.FRC_CAP_ID) || row
            );
            setTableData(updatedData);
            setDialogOpen(false);
            setSelectedRows([]); // Clear the checkbox selection after save
            setSelectAllChecked(false);
            for (let i = 0; i < editFormData.length; i++) {
                var providerUpdateRequestBody;
                var provDpnUpdateRequestBody;
                var provCmsUpdateRequestBody;
                var memberUpdateRequestBody;
                var customerUpdateRequestBody;
                var benefitFactorUpdateRequestBody;

                const object = editFormData[i];
                console.log('The edited object is: ', object);
                if (inputForceCapType == 'PROVIDER') {
                    providerUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CONTR_ID": `${object.CONTRACT_ID}`,
                        "CONTR_LN_ID": `${object.CONTRACT_LN_ID}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                } else if (inputForceCapType == 'PROV DPN') {
                    provDpnUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CONTR_ID": `${object.CONTRACT_ID}`,
                        "CONTR_LN_ID": `${object.CONTRACT_LN_ID}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                } else if (inputForceCapType == 'PROV CMS') {
                    provCmsUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CONTR_ID": `${object.CONTRACT_ID}`,
                        "CONTR_LN_ID": `${object.CONTRACT_LN_ID}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                } else if (inputForceCapType == 'MEMBER') {
                    memberUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CUST_ID": `${splitSourceSystemMemberKey(object.SOURCE_SYSTEM_MEMBER_KEY)[0]}`,
                        "MBR_ID": `${splitSourceSystemMemberKey(object.SOURCE_SYSTEM_MEMBER_KEY)[1]}`,
                        "MBR_ID_SUFX": `${splitSourceSystemMemberKey(object.SOURCE_SYSTEM_MEMBER_KEY)[2]}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                } else if (inputForceCapType == 'CUSTOMER') {
                    customerUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CAP_BEN_PLN_ID": `${object.BENEFIT_PLAN_ID}`,
                        "POL_ID": `${object.EMP_GROUP_NUMBER}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "COV_SRC_SYS_CD": `${object.SOURCE_SYSTEM}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                } else if (inputForceCapType == 'BENEFIT FACTOR') {
                    benefitFactorUpdateRequestBody = {
                        "FRC_CAP_ID": `${object.FRC_CAP_ID}`,
                        "CAP_BEN_PLN_ID": `${object.BENEFIT_PLAN_ID}`,
                        "POL_ID": `${object.EMP_GROUP_NUMBER}`,
                        "CAP_PRD_BEGN_YR_MO": `${object.CAP_PRD_BEGIN}`,
                        "CAP_PRD_END_YR_MO": `${object.CAP_PRD_END}`,
                        "REPROC_CD": `${object.REPO_CD}`,
                        "COV_SRC_SYS_CD": `${object.SOURCE_SYSTEM}`,
                        "UPDT_USER_ID": `${userId}`
                    }
                }

                if (inputForceCapType == 'PROVIDER') {
                    ParameterUpdateRequest(providerUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Provider Info Update successfully', response.data)
                        }
                    });
                } else if (inputForceCapType == 'PROV DPN') {
                    ParameterUpdateRequest(provDpnUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Provider DPN Info Update successfully', response.data)
                        }
                    });
                } else if (inputForceCapType == 'PROV CMS') {
                    ParameterUpdateRequest(provCmsUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Provider CMS Info Update successfully', response.data)
                        }
                    });
                } else if (inputForceCapType == 'MEMBER') {
                    ParameterUpdateRequest(memberUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Member Info Update successfully', response.data)
                        }
                    });
                } else if (inputForceCapType == 'CUSTOMER') {
                    console.log("The Customer request body is:: ", customerUpdateRequestBody);
                    ParameterUpdateRequest(customerUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Customer Info Update successfully', response.data)
                        }
                    });
                } else if (inputForceCapType == 'BENEFIT FACTOR') {
                    ParameterUpdateRequest(benefitFactorUpdateRequestBody).then(response => {
                        if (response.status === 200) {
                            console.log('Benefit Factor Info Update successfully', response.data)
                        }
                    });
                }
            }
            setSaveSuccess(true);
        } else {
            setOpenSnackbar(true);
        }
    };

    // Handle Cancel Click (Close and reset form)
    const handleCancelClick = () => {
        setDialogOpen(false);
        setEditFormData([]); // Reset the form data
    };

    // Handle Input Change for editing form data
    const handleInputChange = (event, id) => {
        const { name, value } = event.target;
        const updatedFormData = editFormData.map((row) =>
            row.FRC_CAP_ID === id ? { ...row, [name]: value } : row
        );
        setEditFormData(updatedFormData);
    };

    // Handle Source System Selection Change
    const handleSourceSystemSelectionChange = (event, id) => {
        const { name, value } = event.target;
        const updatedFormData = editFormData.map((row) =>
            row.FRC_CAP_ID === id ? { ...row, [name]: value } : row
        );
        setEditFormData(updatedFormData);
        setSourceSystemSelections(prevSelections => ({
            ...prevSelections,
            [id]: value,
        }));
    };

    // Handle Snackbar Close
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Disabled the feilds based on the option
    const isFieldEnabled = (field) => {
        if (inputForceCapType === 'PROVIDER') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (inputForceCapType === 'PROV DPN') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'divPanelNetwork' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (inputForceCapType === 'PROV CMS') {
            return field === 'contractNumber' || field === 'contractLineNumber' || field === 'cmsContrPbpSegment' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (inputForceCapType === 'MEMBER') {
            return field === 'memberId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (inputForceCapType === 'CUSTOMER') {
            return field === 'sourceSystem' || field === 'employerGroup' || field === 'benefitPlanId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        if (inputForceCapType === 'BENEFIT FACTOR') {
            return field === 'sourceSystem' || field === 'employerGroup' || field === 'benefitPlanId' || field === 'beginCapPeriod' || field === 'endCapPeriod' || field === 'reprocessIndicator';
        }
        return false;
    }

    if (!showTable && !inputError) {
        return null;
    }

    const rowCount = tableData.length;

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    return (
        <div>
            {inputError && (
                <Grid container spacing={.10} style={{ margin: '50px 0px 0px 0px' }}>
                    <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>{inputError}</div>
                </Grid>
            )}
            {/* Table for displaying data */}

            {inputForceCapType == 'PROVIDER' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Name</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Begin Cap Period" sortKey="CAP_PRD_BEGIN" start='ascending'/>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="End Cap Period" sortKey="CAP_PRD_END" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Process Month" sortKey="PROC_YR_MONTH" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Reprocess Indicator" sortKey="REPO_CD" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.CONTRACT_ID === "None" || row.CONTRACT_ID === null ? " " : row.CONTRACT_ID}</TableCell>
                                                <TableCell>{row.CONTRACT_NAME === "None" || row.CONTRACT_NAME === null ? " " : row.CONTRACT_NAME}</TableCell>
                                                <TableCell>{row.CONTRACT_LN_ID === "None" || row.CONTRACT_LN_ID === null ? " " : row.CONTRACT_LN_ID}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='medium' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='medium' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {inputForceCapType == 'PROV DPN' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Name</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Begin Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>End Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Process Month</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Reprocess Indicator</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.CONTRACT_ID === "None" || row.CONTRACT_ID === null ? " " : row.CONTRACT_ID}</TableCell>
                                                <TableCell>{row.CONTRACT_NAME === "None" || row.CONTRACT_NAME === null ? " " : row.CONTRACT_NAME}</TableCell>
                                                <TableCell>{row.CONTRACT_LN_ID === "None" || row.CONTRACT_LN_ID === null ? " " : row.CONTRACT_LN_ID}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='medium' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='medium' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {inputForceCapType == 'PROV CMS' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Name</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Begin Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>End Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Process Month</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Reprocess Indicator</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.CONTRACT_ID === "None" || row.CONTRACT_ID === null ? " " : row.CONTRACT_ID}</TableCell>
                                                <TableCell>{row.CONTRACT_NAME === "None" || row.CONTRACT_NAME === null ? " " : row.CONTRACT_NAME}</TableCell>
                                                <TableCell>{row.CONTRACT_LN_ID === "None" || row.CONTRACT_LN_ID === null ? " " : row.CONTRACT_LN_ID}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='medium' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='medium' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {inputForceCapType == 'MEMBER' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Member ID" sortKey="SOURCE_SYSTEM_MEMBER_KEY" start='ascending'/>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Begin Cap Period" sortKey="CAP_PRD_BEGIN" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="End Cap Period" sortKey="CAP_PRD_END" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Process Month" sortKey="PROC_YR_MONTH" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Reprocess Indicator" sortKey="REPO_CD" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.SOURCE_SYSTEM_MEMBER_KEY === "None" || row.SOURCE_SYSTEM_MEMBER_KEY === null ? " " : row.SOURCE_SYSTEM_MEMBER_KEY}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='large' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='large' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {inputForceCapType == 'CUSTOMER' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Employer Group#" sortKey="EMP_GROUP_NUMBER" start='ascending'/>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Benefit Plan ID" sortKey="BENEFIT_PLAN_ID" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Begin Cap Period" sortKey="CAP_PRD_BEGIN" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="End Cap Period" sortKey="CAP_PRD_END" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Process Month" sortKey="PROC_YR_MONTH" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Reprocess Indicator" sortKey="REPO_CD" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                {/* Need to change the Employer Group# and Benefit Plan ID */}
                                                <TableCell>{row.EMP_GROUP_NUMBER === "None" || row.EMP_GROUP_NUMBER === null ? " " : row.EMP_GROUP_NUMBER}</TableCell>
                                                <TableCell>{row.BENEFIT_PLAN_ID === "None" || row.BENEFIT_PLAN_ID === null ? " " : row.BENEFIT_PLAN_ID}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='large' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='large' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {inputForceCapType == 'BENEFIT FACTOR' && !inputError && showTable &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: rowCount > 10 ? 650 : 'auto', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>Update or Delete</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Employer Group#" sortKey="EMP_GROUP_NUMBER" start='ascending'/>
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Benefit Plan ID" sortKey="BENEFIT_PLAN_ID" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Begin Cap Period" sortKey="CAP_PRD_BEGIN" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="End Cap Period" sortKey="CAP_PRD_END" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Process Month" sortKey="PROC_YR_MONTH" />
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                        <SortButton label="Reprocess Indicator" sortKey="REPO_CD" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow key={row.FRC_CAP_ID}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.FRC_CAP_ID)}
                                                        onChange={() => handleCheckboxChange(row.FRC_CAP_ID)}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.EMP_GROUP_NUMBER === "None" || row.EMP_GROUP_NUMBER === null ? " " : row.EMP_GROUP_NUMBER}</TableCell>
                                                <TableCell>{row.BENEFIT_PLAN_ID === "None" || row.BENEFIT_PLAN_ID === null ? " " : row.BENEFIT_PLAN_ID}</TableCell>
                                                <TableCell>{row.CAP_PRD_BEGIN === "None" || row.CAP_PRD_BEGIN === null ? " " : row.CAP_PRD_BEGIN}</TableCell>
                                                <TableCell>{row.CAP_PRD_END === "None" || row.CAP_PRD_END === null ? " " : row.CAP_PRD_END}</TableCell>
                                                <TableCell>{row.PROC_YR_MONTH === "None" || row.PROC_YR_MONTH === null ? " " : row.PROC_YR_MONTH}</TableCell>
                                                <TableCell>{row.REPO_CD === "None" || row.REPO_CD === null ? " " : row.REPO_CD}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter style={{ position: 'sticky', bottom: 0, backgroundColor: '#F5F5F5' }}>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                        /> Select All
                                    </TableCell>
                                    <TableCell colSpan={7}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                            <Button variant="contained" size='large' color="success" onClick={() => handleUpdateClick()}><EditIcon />Update</Button>
                                            <Button variant="contained" size='large' color="error" onClick={handleDeleteClick}><DeleteIcon />Delete</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        sx={{
                            '.MuiTablePagination-selectLabel': {
                                paddingTop: '12px',
                            },
                            '.MuiTablePagination-displayedRows': {
                                paddingTop: '13px',
                            },
                        }}
                    />
                </Paper>
            }
            {/* Dialog for editing */}
            <Dialog
                open={dialogOpen}
                onClose={handleCancelClick}
                fullWidth
                maxWidth='xl'
                PaperProps={{ sx: { overflowX: 'hidden' } }}
            >
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{ mt: 6 }}
                >
                    <Alert onClose={() => setOpenSnackbar(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
                        {errors.map((error, index) => (
                            <div key={index}>
                                {error.contractNumber && <div>Row {index + 1}: {error.contractNumber}</div>}
                                {error.contractLineNumber && <div>Row {index + 1}: {error.contractLineNumber}</div>}
                                {error.beginCapPeriod && <div>Row {index + 1}: {error.beginCapPeriod}</div>}
                                {error.endCapPeriod && <div>Row {index + 1}: {error.endCapPeriod}</div>}
                                {error.reprocessIndicator && <div>Row {index + 1}: {error.reprocessIndicator}</div>}
                                {error.processMonth && <div>Row {index + 1}: {error.processMonth}</div>}
                                {error.sourceSystem && <div>Row {index + 1}: {error.sourceSystem}</div>}
                                {error.benefitPlanId && <div>Row {index + 1}: {error.benefitPlanId}</div>}
                                {error.employerGroup && <div>Row {index + 1}: {error.employerGroup}</div>}
                            </div>
                        ))}
                    </Alert>
                </Snackbar>
                <DialogTitle>Update Selected Row</DialogTitle>
                <DialogContent>
                    <TableContainer
                        sx={{ overflowX: 'auto', width: '100%' }}
                    >
                        <Table>
                            <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Data Segment</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Force Cap Type</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Contract#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Contract Line#</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Source System</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Employer Group</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Benefit Plan ID</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Source Sytem Member Key</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>CMSContr/PBP/Segment</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Div/Panel/Network</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Begin Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>End Cap Period</TableCell>
                                    <TableCell style={{ backgroundColor: '#b0c0da', textAlign: 'left', minWidth: 200 }}>Reprocess Indicator</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editFormData.map((row) => (
                                    <TableRow key={row.FRC_CAP_ID}>
                                        <TableCell>
                                            <Select
                                                size='small'
                                                sx={{ height: '54px' }}
                                                id='data-seg-select'
                                                fullWidth
                                                onChange={handleDataSegmentChange}
                                                value={inputDataSegment}
                                            >
                                                {dataSegments.map((segment) => (
                                                    <MenuItem
                                                        value={segment}
                                                        key={segment}
                                                        name='data-segment-option'
                                                    >
                                                        {segment}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Select
                                                    size='small'
                                                    sx={{ height: '54px' }}
                                                    id='type'
                                                    fullWidth
                                                    value={inputForceCapType}
                                                    onChange={handleForceCapTypeChange}
                                                // onChange={(event) => handleInputChange(event,row.FRC_CAP_ID)}
                                                >
                                                    <MenuItem name='force-cap-type-option' value={"PROVIDER"}>PROVIDER</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"PROV DPN"}>PROV DPN</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"PROV CMS"}>PROV CMS</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"MEMBER"}>MEMBER</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"CUSTOMER"}>CUSTOMER</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"BENEFIT FACTOR"}>BENEFIT FACTOR</MenuItem>
                                                </Select>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="CONTRACT_ID"
                                                label="Contract#"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('contractNumber')}
                                                value={row.CONTRACT_ID}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="CONTRACT_LN_ID"
                                                label="Contract Line#"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('contractLineNumber')}
                                                value={row.CONTRACT_LN_ID}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Select
                                                    size='small'
                                                    sx={{ height: '54px' }}
                                                    id='source_system'
                                                    name='SOURCE_SYSTEM'
                                                    fullWidth
                                                    value={sourceSystemSelections[row.FRC_CAP_ID]}
                                                    onChange={(event) => handleSourceSystemSelectionChange(event, row.FRC_CAP_ID)}
                                                    disabled={!isFieldEnabled('sourceSystem')}
                                                >
                                                    <MenuItem name='force-cap-type-option' value={"DEFAULT"}>--Select Sourse System--</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"ACIS"}>ACIS</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"CES"}>CES</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"COSMOS"}>COSMOS</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"ECAP"}>ECAP</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"MAPS"}>MAPS</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"NDB"}>NDB</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"ORACLE IFS"}>ORACLE IFS</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"PRIME"}>PRIME</MenuItem>
                                                    <MenuItem name='force-cap-type-option' value={"TOPS"}>TOPS</MenuItem>
                                                </Select>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="EMP_GROUP_NUMBER"
                                                label="Employer Group"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('employerGroup')}
                                                value={row.EMP_GROUP_NUMBER}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="BENEFIT_PLAN_ID"
                                                label="Benefit Plan ID"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('benefitPlanId')}
                                                value={!row.BENEFIT_PLAN_ID ? row.BENEFIT_PLAN_ID : ''}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="SOURCE_SYSTEM_MEMBER_KEY"
                                                label="Member ID"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('memberId')}
                                                value={row.SOURCE_SYSTEM_MEMBER_KEY}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="CMS_CONTR_PBP_SEGMENT"
                                                label="CMSContr-PBP-Segment"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('cmsContrPbpSegment')}
                                                // value={row.}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                // name=""
                                                label="Div-Panel-Network"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('divPanelNetwork')}
                                                // value={row.}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="CAP_PRD_BEGIN"
                                                label="Begin Cap Period"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('beginCapPeriod')}
                                                value={row.CAP_PRD_BEGIN}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="dense"
                                                name="CAP_PRD_END"
                                                label="End Cap Period"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('endCapPeriod')}
                                                value={row.CAP_PRD_END}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {/* <TextField
                                                margin="dense"
                                                name="REPO_CD"
                                                label="Reprocess Indicator"
                                                type="text"
                                                sx={{ minWidth: 200 }}
                                                disabled={!isFieldEnabled('reprocessIndicator')}
                                                value={row.REPO_CD}
                                                onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                            /> */}

                                            <Box>
                                                <Select
                                                    margin="dense"
                                                    size='small'
                                                    sx={{ height: '54px' }}
                                                    fullWidth
                                                    name="REPO_CD"
                                                    disabled={!isFieldEnabled('reprocessIndicator')}
                                                    onChange={(event) => handleInputChange(event, row.FRC_CAP_ID)}
                                                    value={row.REPO_CD || 'REPROCESS'}
                                                >
                                                    <MenuItem value={"REPROCESS"}>REPROCESS</MenuItem>
                                                    <MenuItem value={"FORCE"}>FORCE</MenuItem>
                                                </Select>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <Button onClick={handleDialogSave} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
            {/* Snackbar for warnings */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" variant="filled" sx={{ width: '100%' }} >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {/* Snackbar to display the success message */}
            <Snackbar
                open={saveSuccess}
                autoHideDuration={5000}
                onClose={() => setSaveSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setSaveSuccess(false)} severity="success" variant="filled" sx={{ width: '100%' }} >
                    Data saved successfully
                </Alert>
            </Snackbar>
            {/* Snackbar to display the delete success message */}
            <Snackbar
                open={deleteSuccess}
                autoHideDuration={5000}
                onClose={() => setDeleteSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ mt: 6 }}
            >
                <Alert onClose={() => setDeleteSuccess(false)} severity="success" variant="filled" sx={{ width: '100%' }} >
                    Data deleted successfully
                </Alert>
            </Snackbar>
        </div>
    );
};
export default ParametersSeachTable;