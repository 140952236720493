import Button from '@mui/material/Button'
import { IconButton, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from "@mui/material/TableFooter";
import * as React from 'react';
import * as Constants from "../../../MemberTab/MemberHistoryTab/MemberTotalCap/TotalCapConstants";
//import axios from "axios";
import { useState, useEffect } from "react";
import { HistoryTotalCapApi } from "./HistoryTotalCapApi";
import { CloudDownload } from "@mui/icons-material";
import { formatDollars } from '../../../helperFunctions';

// Initialize the cache outside of the component  
const apiCache = new Map();

const HistoryTotalCap = ({
    memberData,
    dataSegmentInfo,
    contractNameInfo,
    switchTabs,
    inputDataSeg,
    inputContractInfo,
    dataSegmentGlobal
}) => {

    const [providerHistory, setProviderHistory] = useState([]);
    const [totals, setTotals] = useState({});
    const [dataSeg, setDataSeg] = useState();
    const [contractInfo, setContractInfo] = useState();

    useEffect(() => {
        setDataSeg(inputDataSeg);
    }, [inputDataSeg])
    useEffect(() => {
        setContractInfo(inputContractInfo);
    }, [inputContractInfo])



    console.log("data segment info", dataSegmentInfo);

    console.log("contract info", contractNameInfo);

    const handleDownload = () => {

        const table = document.querySelector('.MuiTable-root');

        if (!table) {
            console.error('Table not found');
            return;
        }

        const headers = [];
        table.querySelectorAll('th').forEach(header => {
            headers.push(header.innerText);
        });

        const data = [headers];
        const tableBodyRows = table.querySelectorAll('tbody tr');

        tableBodyRows.forEach(row => {
            const rowData = [];
            row.querySelectorAll('td').forEach(cell => {
                rowData.push(cell.innerText);
            });
            data.push(rowData);
        });

        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'ProviderTotalCap.csv';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        // Check if memberData is available and use its properties for the API call
        if (memberData) {

            const CONTR_PARTN_ID = dataSegmentGlobal;
            const PAY_PARTN_ID = dataSegmentGlobal;
            const { PROC_YR_MO, CONTR_ID, CONTR_LN_ID, ASSOC_PROV_ID } = memberData;
            const cacheKey = `${PROC_YR_MO}-${CONTR_ID}-${CONTR_LN_ID}-${PAY_PARTN_ID}-${CONTR_PARTN_ID}-${ASSOC_PROV_ID}`;

            //   console.log("Navigating to TotalCap with DATA_SEG_ID:", DATA_SEG_ID);
            console.log("Navigating to TotalCap with PROC_YR_MO:", PROC_YR_MO);
            console.log("Navigating to TotalCap with CAP_PRD_YR_MO:", PAY_PARTN_ID);
            //    console.log("Navigating to TotalCap with ECAP_MBR_KEY:", ECAP_MBR_KEY);
            //   console.log("Navigating to TotalCap with ECAP_CONTR_LN_KEY:", ECAP_CONTR_LN_KEY);

            // Check if the data is already in the cache  
            if (apiCache.has(cacheKey)) {
                // Use the cached data  
                const cachedData = apiCache.get(cacheKey);
                setProviderHistory(cachedData.sort((a, b) => a.CAP_PERIOD.localeCompare(b.CAP_PERIOD)));
                setTotals(cachedData.TOTAL);
            } else {

                HistoryTotalCapApi(PROC_YR_MO, CONTR_ID, CONTR_LN_ID, PAY_PARTN_ID, CONTR_PARTN_ID, ASSOC_PROV_ID)
                    .then(response => {
                        console.log("API response:", response);

                        console.log("Cache key:", cacheKey);
                        console.log("Cached data:", apiCache.get(cacheKey));
                        // Cache the response data  
                        apiCache.set(cacheKey, response.data);
                        // Sort and set the provider history  
                        const sortedData = response.data.sort((a, b) => a.CAP_PERIOD.localeCompare(b.CAP_PERIOD));
                        setProviderHistory(sortedData);
                    })
                    .catch(error => {
                        console.error('There was an error fetching the provider history:', error);
                    });
            }
        }
    }, [memberData]); // The empty array ensures this effect runs only once after the initial render

    function sumByProperty(arr, propName) {
        return arr.reduce((total, obj) => {
            const value = parseFloat(obj[propName]);
            return total + (isNaN(value) ? 0 : value);
        }, 0);
    }


    return (

        <div>
            <br></br>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Contract#: {memberData?.CONTR_ID} | Contract Line#: {memberData?.CONTR_LN_ID} Data Segment: {dataSeg}  | Arrangement: {memberData?.PROV_ARNG_CD}|  Cap Contract Type: {memberData?.CONTR_TYP_CD}|  Total Cap: <a href='#' onClick={() => switchTabs(2, dataSegmentInfo)} style={{ cursor: 'pointer' }}>{`${formatDollars(parseFloat(memberData.TOTAL_CAP).toFixed(2))}`}</a>

                <br />
                Contact Name: {contractInfo}
            </Typography>


            <div style={{ marginTop: "1rem", width: '100%' }}>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleDownload}
                        id='download-button'
                    >
                        Download
                    </Button>
                </div>



                {/* <div>
                {loading ? (<CircularProgress/>) : error ? (<p>{error}</p>) : ( */}
                {/* <Paper sx={{ width: '149%' }}> */}
                <TableContainer sx={{ maxHeight: 590 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead>

                            <TableRow>
                                <Constants.StyledTableCell align="left" sx={{ width: '10%' }}>
                                    Assoc Group</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Process Month</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Cap Period</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Adds</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Terms</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Demos</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Std Svc Amount</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Adj Amount</Constants.StyledTableCell>
                                <Constants.StyledTableCell align="left">Total Cap</Constants.StyledTableCell>

                            </TableRow>
                        </TableHead>


                        <Constants.StripedTableBody>

                            {providerHistory && providerHistory.map((provider, index) => (

                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell align="left" sx={{ paddingRight: '15px' }}>{provider.ASSOC_GROUP}</TableCell>
                                    <TableCell align="left" >{provider.PROCESS_MONTH}</TableCell>
                                    <TableCell align="left" >{provider.CAP_PERIOD}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.ADDS)}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.TERMS)}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.DEMOS)}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.STD_SVC_AMOUNT)}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.ADJ_AMOUNT)}</TableCell>
                                    <TableCell align="left" >{formatDollars(provider.TOTAL_CAP)}</TableCell>
                                </TableRow>
                            ))}

                        </Constants.StripedTableBody>
                        {/* <TableFooter>
                            <TableRow className="table-light" sx={{ borderLeftStyle: 'solid', borderLeftWidth: '0.1em' }} align="left">
                                {Object.values(footerData).map((value, index) => (
                                    <td key={index}><strong>{value}</strong></td>
                                ))}
                            </TableRow>
                        </TableFooter> */}
                    </Table>
                </TableContainer>

                {/* </Paper> */}
            </div>
        </div>
    );
};

export default HistoryTotalCap;
