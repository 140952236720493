import {
    Alert,
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { testPDSeg } from "../../../testPopupDataSegment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReportingOverridesAddTable } from "./ReportingOverridesAddTable";
import { overridesSaveApi, overridesPopulateApi } from "./ReportingOverridesAddApi";
import { getDataSegmentIntFromString } from "../../../Home/dataSegmentApi";
import { formatYearMonth } from "../../../helperFunctions";
import { PopupContractMpin } from "../../../PopupContractMpin";
import { contractMpinApi } from "../../../contractMpinApi";

export const ReportingOverridesAdd = ({
    inputDataSegments,
}) => {
    const [dataSegment, setDataSegment] = useState('');
    const [providerArrangement, setProviderArrangement] = useState('');
    const providerArrangements = {
        'PRIMARY CARE': 'PC',
        'THIRD PARTY': 'TP'
    }
    const [providerId, setProviderId] = useState('');
    const [beginProcYr, setBeginProcYr] = useState(null);
    const [endProcYr, setEndProcYr] = useState(null);
    const [showSearch, setShowSearch] = useState(true);
    const [showBody, setShowBody] = useState(false);

    const [dataArray, setDataArray] = useState();

    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [savingLoad, setSavingLoad] = useState(false);

    const [inputtedData, setInputtedData] = useState();

    function dataSegmentChange(event) {
        if (!dataSegment) {
            setDataSegment(event.target.value);
        } else if (dataSegment && window.confirm('Changing the Data Segment will refresh the screen. Any data entered will be cleared. Okay to continue')) {
            setDataSegment(event.target.value);
        }
    }

    function providerArrangementChange(event) {
        setProviderArrangement(event.target.value);
    }

    function openPopupDataSegment() {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setDataSegment(response[1]);
            } catch {
                console.log('popup receive message error');
            }
        };
    }

    function openPopupContractMPIN() {
        if (dataSegment) {
            PopupContractMpin(dataSegment);
            window.onmessage = (event) => {
                try {
                    if (/^\d+$/.test(event.data)) {
                        setProviderId(event.data);
                    }
                } catch (error) {
                    console.log('invalid popup response');
                }
            }
        } else {
            window.alert('Please select a Datasegment');
        }
    }

    function handleReset() {
        setDataSegment('');
        setProviderArrangement('');
        setProviderId('');
        setBeginProcYr(null);
        setEndProcYr(null);
    }

    useEffect(() => {
        handlePopulate(false);
    }, [dataSegment])

    function handlePopulate(populate) {
        if (loading) {
            return;
        }
        setShowBody(false);
        setErrorMessages([]);
        var tempErrorMessages = [];
        if (!dataSegment && !providerArrangement && populate) {
            setErrorMessages(prevState => [
                ...prevState,
                ['error', 'Data Segment is required.'],
                ['error', 'Provider Arrangement is required.']
            ]);
        } else if (dataSegment) {
            setLoading(true);
            var runQuery = true;
            var begin = formatYearMonth(beginProcYr);
            var end = formatYearMonth(endProcYr);
            if (providerId) {
                // ensure provider id is valid
                contractMpinApi({ 'CONTR_MPIN': providerId }).then(response => {
                    if (response.data.length === 0) {
                        runQuery = false;
                        tempErrorMessages.push(['error', 'Contract Owner MPIN does not exist in NDB']);
                    }
                    if (begin && end) {
                        if (parseInt(end) < parseInt(begin)) {
                            runQuery = false;
                            tempErrorMessages.push(['error', 'Begin Process Period is after the End Process Month']);
                        }
                    }
                    if (!runQuery) {
                        setLoading(false);
                        setErrorMessages(tempErrorMessages);
                    }
                }).catch(error => {
                    runQuery = false;
                    tempErrorMessages.push(['error', 'Contract Owner MPIN does not exist in NDB']);
                    if (begin && end) {
                        if (parseInt(end) < parseInt(begin)) {
                            runQuery = false;
                            tempErrorMessages.push(['error', 'Begin Process Period is after the End Process Month']);
                        }
                    }
                    setLoading(false);
                    setErrorMessages(tempErrorMessages);
                })
            }
            if (runQuery) {
                var requestBody = {
                    "DATA_SEG_ID": getDataSegmentIntFromString(dataSegment),
                    "PROV_ID": providerId,
                    "BEGN_PROC_PRD": begin,
                    "END_PROC_PRD": end
                }
                if (providerArrangement) {
                    requestBody["PROV_ARNG_CD"] = providerArrangements[providerArrangement];
                } else {
                    setProviderArrangement(Object.keys(providerArrangements)[0]);
                    requestBody['PROV_ARNG_CD'] = Object.values(providerArrangements)[0]
                }
                console.log('populate:', populate);
                console.log(requestBody);
                overridesPopulateApi(requestBody).then(response => {
                    console.log(response);
                    var temp = response.data;
                    if (!populate) {
                        temp[6] = [];
                    }
                    temp.push({
                        "PROV_ID": requestBody["PROV_ID"],
                        "BEGN_PROC_PRD": begin,
                        "END_PROC_PRD": end
                    })
                    setDataArray(temp);
                    setLoading(false);
                    setShowBody(true);
                    setLoading(false);
                }).catch(error => {
                    if (error.response.data.includes('Existing row found with different provider arrangement for Contract Owner MPIN!')) {
                        setErrorMessages([['error', 'Existing row found with different provider arrangement for Contract Owner MPIN!']]);
                    } else {
                        console.log(error.response.data);
                    }
                    setLoading(false);
                });
            }
        }
    }

    function toggleSearch() {
        setShowSearch(!showSearch);
    }

    // saving
    useEffect(() => {
        if (savingLoad) {
            return;
        }
        setErrorMessages([]);
        var tempErrorMessages = [];
        if (inputtedData !== undefined) {
            if (inputtedData.length === 0) {
                setErrorMessages([['error', 'Please enter at least one row of data.']]);
            } else {
                setSavingLoad(true);
                var intDSeg = getDataSegmentIntFromString(dataSegment);
                var requestBody = inputtedData;
                for (let i = 0; i < requestBody.length; i++) {
                    requestBody[i]['DATA_SEG_ID'] = intDSeg;
                    var validBegin = false;
                    var validEnd = false;
                    var intBeginDate = parseInt(requestBody[i]['BEGN_PROC_PRD']);
                    var intEndDate = parseInt(requestBody[i]['END_PROC_PRD']);
                    if (!requestBody[i]['BEGN_PROC_PRD']) {
                        tempErrorMessages.push(['error', 'Begin Process Period is required']);
                    } else if (
                        intBeginDate < 194001 ||
                        intBeginDate % 100 === 0 ||
                        parseInt(requestBody[i]['BEGN_PROC_PRD'].slice(-2)) > 12
                    ) {
                        tempErrorMessages.push(['error', 'Begin Process Period should be YYYYMM']);
                    } else {
                        validBegin = true;
                    }
                    if (!requestBody[i]['END_PROC_PRD']) {
                        tempErrorMessages.push(['error', 'End Process Period is required']);
                    } else if (
                        intEndDate < 194001 ||
                        intEndDate % 100 === 0 ||
                        parseInt(requestBody[i]['END_PROC_PRD'].slice(-2)) > 12
                    ) {
                        tempErrorMessages.push(['error', 'End Process Period should be YYYYMM']);
                    } else {
                        validEnd = true;
                    }
                    if (validBegin && validEnd && intBeginDate > intEndDate) {
                        tempErrorMessages.push(['error', 'Begin Process Period is after the End Process Month']);
                    }
                    if (!requestBody[i]['CONTRACT_OWNER_MPIN']) {
                        tempErrorMessages = [
                            ['error', `Please check row ${requestBody[i]['row_number'] + 1} for the below error`],
                            ['error', 'Contract Owner MPIN is required'],
                            ...tempErrorMessages
                        ];
                        if (errorMessages.length === 0) {
                            setErrorMessages(tempErrorMessages);
                            setSavingLoad(false);
                            return
                        }
                    }
                    if (tempErrorMessages.length > 0) {
                        setErrorMessages(tempErrorMessages);
                        setSavingLoad(false);
                        return
                    } else {
                        contractMpinApi({ 'CONTR_MPIN': requestBody[i]['CONTRACT_OWNER_MPIN'] }).then(response => {
                            if (response.data.length === 0) {
                                tempErrorMessages = [
                                    ['error', `Please check row ${requestBody[i]['row_number'] + 1} for the below error`],
                                    ['error', 'Contract Owner MPIN does not exist in NDB'],
                                    ...tempErrorMessages
                                ];
                            }
                            if (tempErrorMessages.length > 0 && errorMessages.length === 0) {
                                setErrorMessages(tempErrorMessages);
                                setSavingLoad(false);
                                return
                            } else {
                                
                            }
                        }).catch(error => {
                            console.log(error);
                            tempErrorMessages = [
                                ['error', `Please check row ${requestBody[i]['row_number'] + 1} for the below error`],
                                ['error', 'Contract Owner MPIN does not exist in NDB'],
                                ...tempErrorMessages
                            ];
                            if (tempErrorMessages.length > 0 && errorMessages.length === 0) {
                                setErrorMessages(tempErrorMessages);
                                setSavingLoad(false);
                                return
                            }
                        })
                    }
                }
                overridesSaveApi(requestBody).then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].length === 1) {
                            tempErrorMessages.push(['success', response.data[i]]);
                        } else {
                            for (let j = 0; j < response.data[i].length; j++) {
                                tempErrorMessages.push(['error', response.data[i][j]]);
                            }
                        }
                    }
                    setSavingLoad(false);
                    setErrorMessages(tempErrorMessages);
                }).catch(error => {
                    console.log(error);
                    setSavingLoad(false);
                })
            }
        }
    }, [inputtedData])

    return (
        <Box sx={{ display: 'flex' }}>
            {showSearch &&
                <Box sx={{ flexDirection: 'column', width: 350, display: 'block' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: 350, justifyContent: 'flex-end', alignItems: 'right', ml: 1, mt: 1, mb: 1 }}>
                        <Tooltip title="Advanced Filters">
                            <IconButton>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Saved Search">
                            <IconButton>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset All">
                            <IconButton onClick={() => handleReset()}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Minimize Search">
                            <IconButton onClick={() => toggleSearch()}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Data Segment<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <Select
                                size='small'
                                required
                                onChange={(event) => dataSegmentChange(event)}
                                style={{ width: '100%' }}
                                value={dataSegment}
                                id='data-seg-select'
                                disabled={loading || savingLoad}
                            >
                                {inputDataSegments.map((row) => (
                                    <MenuItem
                                        value={row.NAME}
                                        name='data-segment-option'
                                        key={row.NAME}
                                    >
                                        {row.NAME}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Data segment"
                                onClick={() => openPopupDataSegment()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Provider Arrangement<span style={{ color: 'red' }}>*</span></Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 181 }}>
                            <Select
                                size='small'
                                required
                                onChange={(event) => providerArrangementChange(event)}
                                style={{ width: '100%' }}
                                value={providerArrangement}
                            >
                                {Object.keys(providerArrangements).map((name) => (
                                    <MenuItem
                                        value={name}
                                        key={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Contract Owner Mpin</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
                            <TextField
                                size='small'
                                value={providerId}
                                onChange={(event) => { setProviderId(event.target.value) }}
                            />
                        </Box>
                        <div style={{ height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center', marginLeft: '0.2rem' }}>
                            <img
                                style={{ height: '80%', width: '80%' }}
                                src="Binoculars-icon.png"
                                title="Filtering Contract MPIN"
                                onClick={() => openPopupContractMPIN()}
                            ></img>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>Begin Process Period</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => setBeginProcYr(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={beginProcYr}
                                    value={beginProcYr}
                                    required
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', flex: 1, ml: 1, whiteSpace: 'nowrap' }}>End Process Period</Typography>
                        <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id='process-month-calendar'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 8px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => setEndProcYr(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={endProcYr}
                                    value={endProcYr}
                                    required
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box>
                        <Button
                            id='search-button'
                            size="medium"
                            variant="contained"
                            endIcon={<SendIcon />}
                            onClick={() => handlePopulate(true)}
                            title="Populate table"
                            disabled={loading || savingLoad}
                        >
                            Populate
                        </Button>
                    </Box>
                </Box>
            }
            {
                !showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }
          <Box sx={{ mt: 7, ml: '1rem', width: '100%', overflowX: 'scroll' }}>  
    {savingLoad && (  
        <LinearProgress sx={{ width: '100%' }} />  
    )}  
    {showBody && dataArray && (  
        <ReportingOverridesAddTable  
            inputDataArray={dataArray}  
            showBody={showBody}  
            inputLoading={loading}  
            setInputtedData={setInputtedData}  
        />  
    )}  
</Box>  
            {/* errors alerts, mounted top right */}
            <Box sx={{ position: 'absolute', top: 0, right: '1.3rem', zIndex: '100' }}>
                {errorMessages.map((message, index) => {
                    if (message[0] === 'error') {
                        return (
                            <Alert
                                severity="error"
                                sx={{ mb: 0.5, opacity: '.9', '&:hover': { backgroundColor:'#ffb6a7', cursor: 'pointer' }}}
                                onClose={() => setErrorMessages(errorMessages.filter((_, i) => i !== index))}
                                onClick={() => setErrorMessages(errorMessages.filter((_, i) => i !== index))}
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    } else if (message[0] === 'success') {
                        return (
                            <Alert
                                severity="success"
                                sx={{ mb: 0.5, opacity: '.9', '&:hover': { backgroundColor:'#b7e8c0', cursor: 'pointer' }}}
                                onClose={() => setErrorMessages(errorMessages.filter((_, i) => i !== index))}
                                onClick={() => setErrorMessages(errorMessages.filter((_, i) => i !== index))}
                                key={'alert_' + index}
                            >
                                {message[1]}
                            </Alert>
                        )
                    }
                })}
            </Box>
        </Box >
    )
}