import { Grid, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import CRTSearchTable from './CRTSearchTable';
import React from "react";
import { useState, useEffect } from 'react';
import { crtSearch } from "./crtSearch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LinearProgress from '@mui/material/LinearProgress';
import { HelpButtons } from '../../../components/helpPrintButtons.js';

export const ProviderCRT = () => {
    const [paymentContractNumber, setPaymentContractNumber] = React.useState('');
    const [ownerContractNumber, setOwnerContractNumber] = React.useState('');
    const [contractNumber, setContractNumber] = React.useState('');
    const [capProcessMonth, setCapProcessMonth] = useState(null);
    const [paymentMPIN, setPaymentMPIN] = React.useState('');
    const [ownerMPIN, setOwnerMPIN] = React.useState('');
    const [mpin, setMPIN] = React.useState('');
    const [crtNumber, setCRTNumber] = React.useState('');
    const [tableData, setTableData] = React.useState();
    const [showSearch, setShowSearch] = React.useState(true);
    const [showTable, setShowTable] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handlePaymentContractNumberChange = (event) => {
        setPaymentContractNumber(event.target.value.replace(/\D/g, ''));
    };

    const handleOwnerContractNumberChange = (event) => {
        setOwnerContractNumber(event.target.value.replace(/\D/g, ''));
    }

    const handleContractNumberChange = (event) => {
        setContractNumber(event.target.value.replace(/\D/g, ''));
    }

    const handlePaymentMPINChange = (event) => {
        setPaymentMPIN(event.target.value.replace(/\D/g, ''));
    }

    const handleOwnerMPINChange = (event) => {
        setOwnerMPIN(event.target.value.replace(/\D/g, ''));
    }

    const handleMpinChange = (event) => {
        setMPIN(event.target.value.replace(/\D/g, ''));
    }

    const handleCrtNumberChange = (event) => {
        setCRTNumber(event.target.value.replace(/\D/g, ''));
    }

    const [loadingTable, setLoadingTable] = useState(false);

    function capProcessMonthChange(date) { setCapProcessMonth(date); }

    const handleSubmit = () => {
        if (paymentContractNumber === '' &&
            ownerContractNumber === '' &&
            contractNumber === '' &&
            mpin === '' &&
            crtNumber === '' &&
            paymentMPIN === '' &&
            ownerMPIN === ''
        ) {
            setErrorMessage('Please fill in at least one of the following fields: Payment Contract#, Payment MPIN, Owner Contract#, Owner MPIN, Contract#, MPIN, or CRT#');
            setShowTable(false);
        } else {
            setErrorMessage('');
            setLoadingTable(true);
            const requestBody = {
                "payment_contract": `${paymentContractNumber}`,
                "payment_mpin": `${paymentMPIN}`,
                "owner_contract": `${ownerContractNumber}`,
                "owner_mpin": `${ownerMPIN}`,
                "contract_number": `${contractNumber}`,
                "mpin": `${mpin}`,
                "crt": `${crtNumber}`
            }
            crtSearch(requestBody).then
                (response => {
                    setLoadingTable(true);
                    if (response.data.length > 0) {
                        setTableData(response.data);
                    } else {
                        setErrorMessage('No matching records found');
                    }
                }).catch(error => {
                    setLoadingTable(true);
                    console.log(error);
                    setErrorMessage('Error searching for CRT records');
                });

        }

    };

    useEffect(() => {
        if (tableData !== undefined) {
            if (tableData != [] && tableData.length > 0) {
                setShowTable(true);
            } else {
                setShowTable(false);
            }
        }
    }, [tableData]);

    const handleReset = () => {
        setPaymentContractNumber('');
        setOwnerContractNumber('');
        setContractNumber('');
        setCapProcessMonth('');
        setPaymentMPIN('');
        setOwnerMPIN('');
        setMPIN('');
        setCRTNumber('');
        setShowTable(false);
    };

    function toggleSearch() {
        if (showSearch) {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }
    }

    return (
        <Grid container spacing={2} style={{ margin: '15px 0px 0px 0px' }}>
			<div style={{ position: 'absolute', top: '0', right: '20px', zIndex: 1 }}>
				<HelpButtons index={3} />
			</div>
            {showSearch &&
                <Grid xs={2.8} sm={2.8} md={2.8} lg={2} style={{ padding: '0px 10px 0px 0px' }}>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={4.5}>
                        </Grid>
                        <Grid xs={7.5}>
                            <Grid container spacing={1} style={{ margin: '0px 0px 5px 0px' }}>
                                <Grid xs={2.4} sm={2} md={2} lg={2}>
                                    <Tooltip title="Advanced Filters">
                                        <IconButton>
                                            <FilterListIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={2.4} >
                                    <Tooltip title="Settings">
                                        <IconButton>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={2.4} >
                                    <Tooltip title="Saved Search">
                                        <IconButton>
                                            <BookmarkIcon />
                                        </IconButton>
                                    </Tooltip>

                                </Grid>
                                <Grid xs={2.4} >
                                    <Tooltip title="Reset All">
                                        <IconButton onClick={handleReset}>
                                            <ReplayIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={2.4} >
                                    <Tooltip title="Minimize Search">
                                        <IconButton onClick={() => toggleSearch()}>
                                            <KeyboardArrowLeft />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Payment Contract#
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="payment-contracts-number"
                                name="payment-contracts-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={paymentContractNumber}
                                onChange={handlePaymentContractNumberChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Owner Contract#
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="owner-contract-number"
                                name="owner-contract-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={ownerContractNumber}
                                onChange={handleOwnerContractNumberChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Contract#
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="contract-number"
                                name="contract-number"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={contractNumber}
                                onChange={handleContractNumberChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Cap Process Month
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3} style={{ padding: '0px 0px 0px 0px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id='cap-process-month'
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            height: '35px',
                                            padding: '4px 10px',
                                        },
                                        "& .MuiInputBase-root": {
                                            fontSize: '16px',
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            error: false
                                        },
                                    }}
                                    onChange={(date) => capProcessMonthChange(date)}
                                    format="YYYY MM"
                                    openTo="year"
                                    views={['year', 'month']}
                                    selected={capProcessMonth}
                                    value={capProcessMonth}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Payment MPIN
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="payment-mpin"
                                name="payment-mpin"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={paymentMPIN}
                                onChange={handlePaymentMPINChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                Owner MPIN
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="owner-mpin"
                                name="owner-mpin"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={ownerMPIN}
                                onChange={handleOwnerMPINChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                MPIN
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="mpin"
                                name="mpin"
                                // fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={mpin}
                                onChange={handleMpinChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '0px 0px 5px 0px' }}>
                        <Grid xs={5.7} style={{ padding: '8.5px 0px 0px 0px' }}>
                            <InputLabel
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: 'bold',
                                    fontSize: '.85rem',
                                    fontSize: '0.875rem',
                                    color: 'black',
                                    flex: 1,
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                CRT#
                            </InputLabel>
                        </Grid>
                        <Grid xs={6.3}>
                            <TextField
                                required
                                id="crt-number"
                                name="crt-number"
                                //fullWidth
                                size="small"
                                autoComplete="off"
                                variant="outlined"
                                value={crtNumber}
                                onChange={handleCrtNumberChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={.10} style={{ margin: '25px 0px 0px 0px' }}>
                        <Grid xs={4.5}>
                            <Button
                                variant="contained"
                                id='search-button'
                                size="medium"
                                endIcon={<SendIcon />}
                                onClick={handleSubmit}
                            >SEARCH</Button>
                        </Grid>
                        <Grid xs={7.5}>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/* {loadingTable &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ ml: '1rem', mt: '3.5rem' }} />
                </Box>
            } */}

            {!showSearch &&
                <div>
                    <Tooltip title="Maximize Search">
                        <IconButton onClick={() => toggleSearch()}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>
            }

            {!showSearch &&
                <Grid xs={9.2} sm={12} md={12} lg={11.70}>
                    <Grid container spacing={.10} style={{ margin: '10px 0px 0px 0px' }}></Grid>
                    <CRTSearchTable showTable={showTable} inputTableData={tableData}></CRTSearchTable>
                </Grid>
            }

            {showSearch && !errorMessage &&
                <Grid xs={9.2} sm={12} md={12} lg={10}>
                    <Grid container spacing={.10} style={{ margin: '15px 0px 0px 0px' }}></Grid>
                    <CRTSearchTable showTable={showTable} inputTableData={tableData}></CRTSearchTable>
                </Grid>
            }

            {errorMessage &&
                <Grid xs={9.2} sm={12} md={12} lg={9.5}>
                    <Grid container spacing={.10} style={{ margin: '50px 0px 0px 0px' }}>
                        {errorMessage && (<div style={{ width: '100%', textAlign: 'center', color: 'red' }}>{errorMessage}</div>)}
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default ProviderCRT;