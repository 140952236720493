import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Button, Typography, Box, LinearProgress } from '@mui/material';
import { getDataSegmentStringFromInt } from '../../Home/dataSegmentApi';
import { AgingDetailApi } from './AgingApi';
import { getAgingDetail, setAgingDetail } from '../errorPersistence';
import useSortConfig from '../../sort_config';


export const AgingDetail = ({
    setRenderChoice,
    inputDetailData,
    adjustContractDetailData
}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingTable, setLoadingTable] = useState(true);
    const { sortConfig, SortButton } = useSortConfig();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDownload = () => {  
		// Define the headers based on the table columns you want to include  
        const headers = [  
			'Source System', 'Error#', 'Error Field', 'Process Month',  
			'Cap Period', 'Member ID', 'Contract MPIN',  
			'Contract#', 'Contract Line#', 'Employer Group#', 'Time Stamp', 'Error Comment'
		];  
        const csvContent = [
            headers.join(','), // header row first
            ...sortedData.map(row => [
                row.SRC_SYS_CD, row.ERR_ID, row.ERR_FLD_NM, row.PROC_YR_MO,
                row.CAP_PRD_YR_MO, row.MBR_ID, row.PROV_ID, row.CONTR_ID,
                row.CONTR_LN_ID, row.POL_ID, formatDate(row.CREAT_DTTM), row.COMMT
            ].join(',')) // map each row of data into a CSV string
        ].join('\n'); // join rows with newline character
        
        // Create a Blob from the CSV Content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        // Create a link and set the URL using createObjectURL
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'AgingDetail.csv'; // Name the file here
        
        // Append to the document
        document.body.appendChild(link);
        
        // Trigger 'click' to download
        link.click();
        
        // Clean up and remove the link
        document.body.removeChild(link);
    };

    useEffect(() => {
        console.log(inputDetailData);
        var temp = getAgingDetail();
        if (temp) {
            setTableData(temp);
            setLoadingTable(false);
            setShowTable(true);
        } else {
            if (inputDetailData['over6']) {
                setLoadingTable(false);
                setShowTable(false);
                setErrorMessage('No data found');
            } else {
                AgingDetailApi(inputDetailData).then(response => {
                    setLoadingTable(false);
                    if (response.data.length > 0) {
                        setAgingDetail(response.data);
                        setShowTable(true);
                        setTableData(response.data);
                        adjustContractDetailData('DATA_SEG_ID', inputDetailData['DATA_SEG_ID']);
                        adjustContractDetailData('JOB_TYP_CD', inputDetailData['JOB_TYP_CD']);
                        adjustContractDetailData('Process_month', inputDetailData['Process_month']);
                        adjustContractDetailData('End_Month', inputDetailData['End_Month']);
                        adjustContractDetailData('PROV_ID', inputDetailData['PROV_ID']);
                        adjustContractDetailData('POL_ID', inputDetailData['POL_ID']);
                        adjustContractDetailData('CUST_ID', inputDetailData['CUST_ID']);
                        adjustContractDetailData('MBR_ID', inputDetailData['MBR_ID']);
                        adjustContractDetailData('MBR_ID_SUFX', inputDetailData['MBR_ID_SUFX']);
                        adjustContractDetailData('ERR_TYP_CD', inputDetailData['ERR_TYP_CD']);
                        adjustContractDetailData('ERR_SEV_CD', inputDetailData['ERR_SEV_CD']);
                        adjustContractDetailData('LNG_DESC', inputDetailData['LNG_DESC']);
                        adjustContractDetailData('CNT', inputDetailData['CNT']);
                        adjustContractDetailData('PRD_AGE_CD', inputDetailData['PRD_AGE_CD']);
                    } else {
                        setErrorMessage('No data found');
                    }
                }).catch(error => {
                    console.log(error);
                    setLoadingTable(false);
                    setErrorMessage('No data found');
                });
            }
        }
    }, []);

    function agingDetailClick(PROV_ID, CONTR_ID, CONTR_LN_ID, CAP_PRD_YR_MO) {
        adjustContractDetailData('PROV_ID', PROV_ID);
        adjustContractDetailData('CONTR_ID', CONTR_ID);
        adjustContractDetailData('CONTR_LN_ID', CONTR_LN_ID);
        adjustContractDetailData('CAP_PRD_YR_MO', CAP_PRD_YR_MO);
        setRenderChoice(2);
    }

    const sortedData = [...tableData].sort((a, b) => {
        for (const column in sortConfig) {
            if (sortConfig[column] !== 'default') {
                const aValue = a[column];
                const bValue = b[column];
                if (sortConfig[column] === 'ascending') {
                    if (aValue > bValue) return 1;
                    if (aValue < bValue) return -1;
                } else if (sortConfig[column] === 'descending') {
                    if (aValue < bValue) return 1;
                    if (aValue > bValue) return -1;
                }
            }
        }
        return 0;
    });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };


    return (
        <div>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Data Segment: {getDataSegmentStringFromInt(inputDetailData['DATA_SEG_ID'])} | Error Severity: {inputDetailData['ERR_SEV_CD']} | Error Category: {inputDetailData['JOB_TYP_CD']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Error Code: {inputDetailData['ERR_TYP_CD']} | Error Message: {inputDetailData['LNG_DESC']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Current Count: {inputDetailData['CNT']}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                Back to: <a href='#' onClick={() => setRenderChoice(0)}>Aging Search Results</a> {'>'} Aging Detail
            </Typography>
            {showTable &&
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDownload}
                            id='download-button'
                        >
                            Download
                        </Button>
                    </div>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead style={{ backgroundColor: '#b0c0da' }}>
                                    <TableRow style={{ backgroundColor: '#b0c0da' }}>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Source System</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Field</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                            <SortButton label="Process Month" sortKey="PROC_YR_MO" start='ascending'/>
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>
                                            <SortButton label="Cap Period" sortKey="CAP_PRD_YR_MO" />
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Member ID</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract MPIN</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Contract Line#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Employer Group#</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Time Stamp</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#b0c0da', textAlign: 'left' }}>Error Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow key={row.ERR_TYP_CD}>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.SRC_SYS_CD}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.ERR_FLD_NM}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.PROC_YR_MO}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CAP_PRD_YR_MO}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.MBR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}><a href='#' onClick={() => agingDetailClick(row.PROV_ID, row.CONTR_ID, row.CONTR_LN_ID, row.CAP_PRD_YR_MO)}>{row.PROV_ID}</a></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CONTR_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.CONTR_LN_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.POL_ID}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{formatDate(row.CREAT_DTTM)}</TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{row.COMMT}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tableData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    paddingTop: '12px',
                                },
                                '.MuiTablePagination-displayedRows': {
                                    paddingTop: '13px',
                                },
                            }}
                        />
                    </Paper>
                </div>
            }
            {loadingTable &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ mt: '2rem' }} />
                </Box>
            }
            {errorMessage && (
                <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
