export const rolesMap = {
  "ECap Admin": "ecapadmn",
  "Non ECap": "ecapnon",
  "ECap Associate": "ecapasso",
  "ECap Analyst": "ecapanly",
  "ECap Lead": "ecaplead",
  "Supervisor": "ecapsupv",
  "Manager": "ecapmgr",
  "Director": "ecapdir",
  "Senior Management": "ecapmgmt",
  "Select": "",
};
export const rolesPermissionDefaultMap = {
  "ADMIN (ALL)": { view: true, update: true, delete: true, add: true },
  Roles: { view: true, update: true, delete: true, add: true },
  Codes: { view: true, update: true, delete: true, add: true },
  "Group Contracts": { view: true, update: true, delete: true, add: true },
  "Exclude Contracts": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  Approval: { view: true, update: true, delete: true, add: true },
  "MAINTENANCE (All)": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  "Force Cap Parameters": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  "Cap/Payment Adjustments": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  "Retro Period Control": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  "Premium Factors": { view: true, update: true, delete: true, add: true },
  "Benefit Factors": { view: true, update: true, delete: true, add: true },
  "Reporting Overrides": {
    view: true,
    update: true,
    delete: true,
    add: true,
  },
  "MEMBER (All)": { view: true, update: true, delete: true, add: true },
  History: { view: true, update: true, delete: true, add: true },
  "Member Search": { view: true, update: true, delete: true, add: true },
  "PROVIDER (All)": { view: true, update: true, delete: true, add: true },
  Contract: { view: true, update: true, delete: true, add: true },
  Demographics: { view: true, update: true, delete: true, add: true },
  "Provider History": { view: true, update: true, delete: true, add: true },
  CRT: { view: true, update: true, delete: true, add: true },
  Association: { view: true, update: true, delete: true, add: true },
  ERRORS: { view: true, update: true, delete: true, add: true },
  "JOB SCHEDULING": { view: true, update: true, delete: true, add: true },
  "Reports (All)": { view: true, update: true, delete: true, add: true },
  Member: { view: true, update: true, delete: true, add: true },
  Benefit: { view: true, update: true, delete: true, add: true },
  Provider: { view: true, update: true, delete: true, add: true },
  ECapProcess: { view: true, update: true, delete: true, add: true },
  Payment: { view: true, update: true, delete: true, add: true },
  Premium: { view: true, update: true, delete: true, add: true },
};
export const rolesPermissionMap = {
  "ADMIN (ALL)": { view: false, update: false, delete: false, add: false },
  Roles: { view: false, update: false, delete: false, add: false },
  Codes: { view: false, update: false, delete: false, add: false },
  "Group Contracts": { view: false, update: false, delete: false, add: false },
  "Exclude Contracts": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  Approval: { view: false, update: false, delete: false, add: false },
  "MAINTENANCE (All)": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  "Force Cap Parameters": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  "Cap/Payment Adjustments": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  "Retro Period Control": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  "Premium Factors": { view: false, update: false, delete: false, add: false },
  "Benefit Factors": { view: false, update: false, delete: false, add: false },
  "Reporting Overrides": {
    view: false,
    update: false,
    delete: false,
    add: false,
  },
  "MEMBER (All)": { view: false, update: false, delete: false, add: false },
  History: { view: false, update: false, delete: false, add: false },
  "Member Search": { view: false, update: false, delete: false, add: false },
  "PROVIDER (All)": { view: false, update: false, delete: false, add: false },
  Contract: { view: false, update: false, delete: false, add: false },
  Demographics: { view: false, update: false, delete: false, add: false },
  "Provider History": { view: false, update: false, delete: false, add: false },
  CRT: { view: false, update: false, delete: false, add: false },
  Association: { view: false, update: false, delete: false, add: false },
  ERRORS: { view: false, update: false, delete: false, add: false },
  "JOB SCHEDULING": { view: false, update: false, delete: false, add: false },
  "Reports (All)": { view: false, update: false, delete: false, add: false },
  Member: { view: false, update: false, delete: false, add: false },
  Benefit: { view: false, update: false, delete: false, add: false },
  Provider: { view: false, update: false, delete: false, add: false },
  ECapProcess: { view: false, update: false, delete: false, add: false },
  Payment: { view: false, update: false, delete: false, add: false },
  Premium: { view: false, update: false, delete: false, add: false },
};
export const rolesValueMap = {
  admin: "ADMIN (ALL)",
  admin_roles: "Roles",
  admin_codes: "Codes",
  admin_group: "Group Contracts",
  admin_exclude: "Exclude Contracts",
  admin_approval: "Approval",
  maintenance: "MAINTENANCE (All)",
  maintenance_cappayments: "Cap/Payment Adjustments",
  maintenance_retro: "Retro Period Control",
  maintenance_premium: "Premium Factors",
  maintenance_benefit: "Benefit Factors",
  maintenance_reportingoverrides: "Reporting Overrides",
  maintenance_forcecap: "Force Cap Parameters",
  member: "MEMBER (All)",
  member_history: "History",
  member_search: "Member Search",
  provider: "PROVIDER (All)",
  provider_contract: "Contract",
  provider_demographics: "Demographics",
  provider_history: "Provider History",
  provider_crt: "CRT",
  provider_association: "Association",
  errors: "ERRORS",
  job_scheduling: "JOB SCHEDULING",
  reports: "Reports (All)",
  reports_member: "Member",
  reports_benefit: "Benefit",
  reports_provider: "Provider",
  reports_ecapprocess: "ECapProcess",
  reports_payment: "Payment",
  reports_premium: "Premium",
};
